import React from 'react';
import './EventCard.css';

const EventCard = ({ date, day, events, onDeleteEvent }) => {
  return (
    <div className="event-card">
      <div className="event-date">
        <div className="date">{date}</div>
        <div className="day">{day}</div>
      </div>
      <div className="event-details">
        {events.map((event, index) => (
          <div key={event.id} className="event-item">
            <div className="event-title">
              <h5>{event.title}</h5>
              <div className="event-info">
                <span>Project Name</span>
                <span className="event-time">
                  <img src="/images/clock.svg" alt="clock" id="clock" />
                  {event.time}
                </span>
                <span className="event-location">
                  <img src="/images/location.svg" alt="location" id="location" />
                  {event.location}
                </span>
              </div>
            </div>
            <div className="vector-cont event-arrow">
              <img src="/images/Vector.svg" alt="arrow vector" className="" />
            </div>
            <button className="delete-button" onClick={() => onDeleteEvent(date, event.id)}>
              <img src="/images/delete.svg" alt="delete icon" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventCard;
