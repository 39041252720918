import React, { useState } from "react";
import styles from "../Projects.module.css";
import CustomTextInputWithIcon from "../../../../components/inputs/textInputWithIcon/CustomInputWithIcon";
import ButtonWithIcon from "../../../../components/btnWithIcon/ButtonWithIcon";
import { MdSearch } from "react-icons/md";
import { FaList } from "react-icons/fa";
import { HiOutlineViewColumns } from "react-icons/hi2";
import { IoFilter } from "react-icons/io5";
import { Dropdown } from "react-bootstrap";

const ProjectHead = ({ viewType, setViewType }) => {
  return (
    <>
      <div className={styles.breadcrumbs}>
        <a href="#">Workspace / Projects / Category 1 / Project 1</a>
      </div>

      <div className={styles.projectsControllers}>
        <div className={styles.btns}>
          <ButtonWithIcon
            onClick={() => setViewType("ISSUES")}
            isActive={viewType === "ISSUES"}
            icon={FaList}
            label="List View"
          />
          <span className={styles.boardbtn}>
            <ButtonWithIcon
              onClick={() => setViewType("BOARD")}
              isActive={viewType === "BOARD"}
              icon={HiOutlineViewColumns}
              label="Board View"
            />
          </span>
        </div>
        <div className={styles.filters}>
          <div className={styles.searchfield}>
            <CustomTextInputWithIcon
              //   label="Search"
              value=""
              onChange={console.log("")}
              placeholder="Type to search..."
              icon={MdSearch}
              width="100%"
            />
          </div>
          <span className={styles.btnFilter}>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic" className={styles.toggleBtn}>
                <ButtonWithIcon
                  onClick={() => console.log("filter")}
                  icon={IoFilter}
                  label="Filter"
                />
              </Dropdown.Toggle>

              <Dropdown.Menu className={styles.dropdownMenu}>
                <Dropdown.Item
                  href="#/action-1"
                  className={styles.dropdownItem}
                >
                  Priority
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-2"
                  className={styles.dropdownItem}
                >
                  End Date
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-3"
                  className={styles.dropdownItem}
                >
                  Status
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-3"
                  className={styles.dropdownItem}
                >
                  Assignee
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-3"
                  className={styles.dropdownItem}
                >
                  Label
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>
          <span className={styles.btnFilter}>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic" className={styles.toggleBtn}>
                <ButtonWithIcon
                  onClick={() => console.log("filter")}
                  icon={IoFilter}
                  label="Options"
                />
              </Dropdown.Toggle>

              <Dropdown.Menu className={styles.dropdownMenu}>
                <Dropdown.Item
                  href="#/action-1"
                  className={styles.dropdownItem}
                >
                  Pin
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-2"
                  className={styles.dropdownItem}
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-3"
                  className={styles.dropdownItem}
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>
          <img src="/images/Frame1.png" about="profile image" />
        </div>
      </div>
    </>
  );
};

export default ProjectHead;
