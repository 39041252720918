import React, { useState } from "react";
import DashboardLayout from "../../../layouts/dashboard/DashboardLayout";
import styles from "./Projects.module.css";
import CustomTextInputWithIcon from "../../../components/inputs/textInputWithIcon/CustomInputWithIcon";
import ButtonWithIcon from "../../../components/btnWithIcon/ButtonWithIcon";
import { MdSearch } from "react-icons/md";
import { FaList, FaPlus } from "react-icons/fa";
import { HiOutlineViewColumns } from "react-icons/hi2";
import { IoFilter } from "react-icons/io5";
import CustomCard from "../../../components/cards/todo/CustomCard";
import Issues from "../../../components/dashboard/issues/Issues";
import { Dropdown } from "react-bootstrap";
import BoardView from "./BoardView/kanbanboard";
import ProjectHead from "./ProjectHeader/projecthead";

const Projects = () => {
  const [viewType, setViewType] = useState("BOARD");

  const labels = [
    "Label 1",
    "Label 2",
    "Label 3",
    "Label 1",
    "Label 2",
    "Label 3",
  ];
  const imageUrl = "/images/Frame1.png";

  return (
    <DashboardLayout>
      <div className={styles.container}>
        <ProjectHead viewType={viewType} setViewType={setViewType} />

        {viewType === "ISSUES" ? <Issues /> : <BoardView />}
      </div>
    </DashboardLayout>
  );
};

export default Projects;
