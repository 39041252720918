import React, { useState } from 'react';
import './UpcomingEvents.css';
import DashboardLayout from '../../../../layouts/dashboard/DashboardLayout';
import styles from "../../categories/Categories.module.css";
import EventCard from '../../../../components/cards/EventCard/EventCard';
import { Dropdown } from "react-bootstrap";
import CustomTextInputWithIcon from '../../../../components/inputs/textInputWithIcon/CustomInputWithIcon';
import { MdSearch } from "react-icons/md";
import { IoFilter } from "react-icons/io5";
import ButtonWithIcon from '../../../../components/btnWithIcon/ButtonWithIcon';
import CustomModal from '../../../../components/modals/AddEventModal';

const initialEventsData = [
    {
        date: 'June 28',
        day: 'Thursday',
        events: [
            { id: 1, title: 'Create terms of service and privacy policy for the worknova project.', time: '05:00 PM', location: 'Google Meet' },
            { id: 2, title: 'Create terms of service and privacy policy for the worknova project.', time: '05:00 PM', location: 'On-site' },
            { id: 3, title: 'Create terms of service and privacy policy for the worknova project.', time: '05:00 PM', location: 'Zoom' }
        ]
    },
    {
        date: 'June 29',
        day: 'Friday',
        events: [
            { id: 1, title: 'Create terms of service and privacy policy for the worknova project.', time: '05:00 PM', location: 'Google Meet' },

            { id: 2, title: 'Create terms of service and privacy policy for the worknova project.', time: '05:00 PM', location: 'Zoom' }
        ]
    },
    {
        date: 'June 30',
        day: 'Saturday',
        events: [

            { id: 1, title: 'Create terms of service and privacy policy for the worknova project.', time: '05:00 PM', location: 'On-site' },

        ]
    },
    {
        date: 'July 2',
        day: 'Monday',
        events: [
            { id: 1, title: 'Create terms of service and privacy policy for the worknova project.', time: '05:00 PM', location: 'Google Meet' },
            { id: 2, title: 'Create terms of service and privacy policy for the worknova project.', time: '05:00 PM', location: 'On-site' },

        ]
    },
    // Add more event data here...
];

const UpcomingEvents = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [eventsData, setEventsData] = useState(initialEventsData);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newEvent, setNewEvent] = useState({ date: '', day: '', title: '', time: '', location: '', project: '', link: '', comments: '', newAttendee: '',newAttachment: '' });
    const projects = ['WorkNova', 'Project A', 'Project B']; // Add your projects here
    const locations = ['Google Meet', 'On-site', 'Zoom']; // Add your locations here
    const userIsAdmin = true; // Change this based on your admin check logic
    
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    }
    
    const openModal = () => {
        setIsModalOpen(true);
    };
    
    const closeModal = () => {
        setIsModalOpen(false);
    };
    
    const handleNewEventChange = (event) => {
        const { name, value } = event.target;
        setNewEvent({ ...newEvent, [name]: value });
    };
    
    const addNewEvent = () => {
        const eventIndex = eventsData.findIndex(eventData => eventData.date === newEvent.date);
        const newEventData = {
            id: Math.random(), // Generate a unique ID
            title: newEvent.title,
            time: newEvent.time,
            location: newEvent.location,
            project: newEvent.project
        };
        if (eventIndex !== -1) {
            const updatedEventsData = [...eventsData];
            updatedEventsData[eventIndex].events.push(newEventData);
            setEventsData(updatedEventsData);
        } else {
            setEventsData([...eventsData, { date: newEvent.date, day: newEvent.day, events: [newEventData] }]);
        }
        closeModal();
    };
    
    const deleteEvent = (date, eventId) => {
        setEventsData(prevData =>
            prevData.map(eventData =>
                eventData.date === date
                    ? { ...eventData, events: eventData.events.filter(event => event.id !== eventId) }
                    : eventData
            )
        );
    }
    
    const filteredEvents = eventsData.filter(eventData =>
        eventData.events.some(event => event.title.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    
    return (
        <DashboardLayout>
            <div className={styles.container}>
                <div className={styles.breadcrumbs}>
                    <a href="#">Workspace / Schedule / Upcoming Events</a>
                </div>
    
                <div className="search-add">
                    <div className={styles.projectsControllers}>
                        <div className={styles.filters}>
                            <div className={styles.searchfield}>
                                <CustomTextInputWithIcon
                                    type="text"
                                    placeholder="Search event"
                                    className="search-input"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    icon={MdSearch}
                                    width="100%"
                                />
                            </div>
                            <span className={styles.btnFilter}>
                                <Dropdown>
                                    <Dropdown.Toggle
                                        id="dropdown-basic"
                                        className={styles.toggleBtn}
                                    >
                                        <ButtonWithIcon
                                            onClick={() => console.log("filter")}
                                            icon={IoFilter}
                                            label="Filter"
                                        />
                                    </Dropdown.Toggle>
    
                                    <Dropdown.Menu className={styles.dropdownMenu}>
                                        <Dropdown.Item
                                            href="#/action-1"
                                            className={styles.dropdownItem}
                                        >
                                            Event Date
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            href="#/action-2"
                                            className={styles.dropdownItem}
                                        >
                                            Event Day
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            href="#/action-3"
                                            className={styles.dropdownItem}
                                        >
                                            Event Name
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            href="#/action-3"
                                            className={styles.dropdownItem}
                                        >
                                            Event Location
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </span>
                        </div>
                    </div>
                    <button className="add-event-button" onClick={openModal}>
                        <img src='/images/add.svg' alt='add_icon' id='add_icon' /> Add Event
                    </button>
                </div>
    
                <div className="events-section">
                    <h2>Upcoming Events</h2>
                    {filteredEvents.map((eventData, index) => (
                        <EventCard
                            key={index}
                            date={eventData.date}
                            day={eventData.day}
                            events={eventData.events}
                            onDeleteEvent={userIsAdmin ? deleteEvent : null} // Conditionally pass delete function
                        />
                    ))}
                </div>
                <CustomModal
                    isOpen={isModalOpen}
                    closeModal={closeModal}
                    addEvent={addNewEvent}
                    eventState={newEvent}
                    handleChange={handleNewEventChange}
                    projects={projects}
                    locations={locations}
                />
            </div>
        </DashboardLayout>
    );
    }
    
    export default UpcomingEvents;