import React, { createContext, useState, useEffect } from "react";
// import { decodeToken } from "react-jwt";

export const AuthContext = createContext();

export default ({ children }) => {
  const [token, setToken] = useState("");
  const [authenticated, setAuthenticated] = useState(false);
  const [role, setRole] = useState("ADMIN");

  const userToken = localStorage.getItem("worknova");
  useEffect(() => {
    if (userToken) {
      // const decodedToken = decodeToken(userToken);
      setToken(userToken);
      setAuthenticated(true);
    }
  }, [userToken]);

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        authenticated,
        setAuthenticated,
        role, setRole
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};