import React from "react";
import { motion } from "framer-motion";
import { MdMessage, MdAttachFile } from "react-icons/md";
import { Link } from "react-router-dom";
import DropIndicator from "./dropindicator";

// Default user objects
const defaultUsers = [
    { id: 'user1', name: 'Alice', imageUrl: 'https://via.placeholder.com/30?text=A' },
    { id: 'user2', name: 'Bob', imageUrl: 'https://via.placeholder.com/30?text=B' },
    { id: 'user3', name: 'Charlie', imageUrl: 'https://via.placeholder.com/30?text=C' },
    { id: 'user4', name: 'David', imageUrl: 'https://via.placeholder.com/30?text=D' }
];

const getUserImage = (userId) => {
    const user = defaultUsers.find(user => user.id === userId);
    return user ? user.imageUrl : 'https://via.placeholder.com/30'; // Fallback image
};

const Card = ({
    title = "Untitled",
    id,
    column,
    handleDragStart,
    description = "",
    labels = [],
    message = "",
    users = []
}) => {
    return (
        <>
            <DropIndicator beforeId={id} column={column} />
            <Link to={`/project-details/${id}`} className="no-underline">
                <motion.div
                    layout
                    layoutId={id}
                    draggable="true"
                    onDragStart={(e) => handleDragStart(e, { title, id, column })}
                    className="cursor-grab rounded border border-gray-300 bg-white p-3 active:cursor-grabbing flex flex-col"
                >
                    <div>
                        <div className="font-bold text-black text-lg mb-2">{title}</div>
                        <div className="text-gray-700 mb-3">{description}</div>
                        <div className="flex flex-wrap mb-3">
                            {labels && labels.length > 0 ? (
                                labels.map((label, index) => (
                                    <div key={index} className="bg-blue-100 text-blue-600 px-3 py-1 rounded-full text-xs mr-2 mb-2">
                                        {label}
                                    </div>
                                ))
                            ) : (
                                <div className="text-gray-500 text-xs">No labels</div>
                            )}
                        </div>
                    </div>
                    <div className="mt-auto pt-2 border-t border-gray-200 flex items-center">
                        <div className="relative flex items-center">
                            {users.slice(0, 2).map((userId, index) => (
                                <img
                                    key={userId}
                                    src={getUserImage(userId)}
                                    alt="User"
                                    className={`w-8 h-8 rounded-full absolute transition-transform duration-300 hover:scale-110 hover:shadow-lg ${index === 0 ? 'z-10' : 'z-20'} ${index === 1 ? 'left-4' : 'left-0'}`}
                                    style={{ transform: index === 1 ? 'translateX(-50%)' : 'none' }}
                                />
                            ))}
                        </div>
                        <div className="flex items-center ml-2">
                            <span className="text-gray-500 text-xl mr-2 cursor-pointer"><MdMessage /></span>
                            <span className="text-gray-700">{message}</span>
                            <span className="text-gray-500 text-xl mr-2 cursor-pointer"><MdAttachFile /></span>
                        </div>
                    </div>
                </motion.div>
            </Link>
        </>
    );
};

export default Card;
