import React, { useState } from "react";
import styles from "./styles.module.css";
import CustomTextInputWithIcon from "../../../../components/inputs/textInputWithIcon/CustomInputWithIcon";
import ButtonWithIcon from "../../../../components/btnWithIcon/ButtonWithIcon";
import { MdSearch } from "react-icons/md";
import { FaList } from "react-icons/fa";
import { HiOutlineViewColumns } from "react-icons/hi2";
import { IoFilter } from "react-icons/io5";
import { Dropdown } from "react-bootstrap";

const Header = () => {
  const [viewType, setViewType] = useState("BOARD");
  return (
    <>
      <div className={styles.breadcrumbs}>
        <a href="#">Workspace / Reports / Burn-down Chart</a>
      </div>

      <div className={styles.wrapper}>
        <div className={styles.filters}>
          <div className={styles.searchfield}>
            <CustomTextInputWithIcon
              // label="Search"
              value=""
              onChange={console.log("")}
              placeholder="Type to search..."
              icon={MdSearch}
              width="100%"
            />
          </div>
          <span className={styles.btnFilter}>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic" className={styles.toggleBtn}>
                <ButtonWithIcon
                  onClick={() => console.log("filter")}
                  icon={IoFilter}
                  label="Filter"
                />
              </Dropdown.Toggle>

              <Dropdown.Menu className={styles.dropdownMenu}>
                <Dropdown.Item
                  href="#/action-1"
                  className={styles.dropdownItem}
                >
                  Day
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-2"
                  className={styles.dropdownItem}
                >
                  Time Frame
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-3"
                  className={styles.dropdownItem}
                >
                  Tasks
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-3"
                  className={styles.dropdownItem}
                >
                  Label
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </div>
      </div>
    </>
  );
};

export default Header;
