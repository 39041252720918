import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./CustomButton.module.css"; // Import CSS Module for component-specific styles

const CustomButton = ({
  children,
  onClick,
  type,
  variant,
  size,
  disabled,
  fullWidth,
  customWidth,
}) => {
  const buttonClass = classNames(
    styles.customButton,
    styles[variant], // Apply variant styles (e.g., primary, secondary, outlined)
    styles[size], // Apply size styles (e.g., large, small)
    { [styles.disabled]: disabled },
    { [styles.fullWidth]: fullWidth },
    { [styles.customWidth]: customWidth }
  );

  return (
    <button
      className={buttonClass}
      onClick={onClick}
      type={type}
      disabled={disabled}
      style={{ width: customWidth }}
    >
      {children}
    </button>
  );
};

CustomButton.propTypes = {
  children: PropTypes.node.isRequired, // Renderable children (text or JSX)
  onClick: PropTypes.func, // Optional onClick event handler
  type: PropTypes.oneOf(["button", "submit", "reset"]), // Button type
  variant: PropTypes.oneOf(["primary", "secondary", "outlined"]), // Button variant
  size: PropTypes.oneOf(["large", "small"]), // Button size
  disabled: PropTypes.bool, // Disabled state
  fullWidth: PropTypes.bool, // Full width button
  customWidth: PropTypes.string, // Custom width as a string (e.g., '50%', '200px')
};

CustomButton.defaultProps = {
  type: "button",
  variant: "primary",
  size: "large",
  disabled: false,
  fullWidth: false,
  customWidth: "auto",
};

export default CustomButton;
