import React from 'react';
import ListItems from '../../../../components/listItems/ListItems';
import DashboardLayout from '../../../../layouts/dashboard/DashboardLayout';
import './MyActivities.css';
import { Dropdown } from "react-bootstrap";
import CustomTextInputWithIcon from '../../../../components/inputs/textInputWithIcon/CustomInputWithIcon';
import { MdSearch } from "react-icons/md";
import { IoFilter } from "react-icons/io5";
import ButtonWithIcon from '../../../../components/btnWithIcon/ButtonWithIcon';
import styles from "../../categories/Categories.module.css";

const MyActivities = () => {
    const itemsHeadings = ["Task Name", "Label", "Due Date"];
    const listItems = [
        {
            taskName: "Create terms of service and privacy policy for the worknova project.",
            label: "Label Name",
            dueDate: "05/12/2024",
        },
        {
            taskName: "Create terms of service and privacy policy for the worknova project.",
            label: "Label Name",
            dueDate: "05/12/2024",
        },
        {
            taskName: "Create terms of service and privacy policy for the worknova project.",
            label: "Label Name",
            dueDate: "05/12/2024",
        },
    ];

    return (
        <DashboardLayout>
            <div className={styles.breadcrumbs}>
                <a href="#">Workspace / Schedule / My Activities</a>
            </div>
            <div className="search-add">
                <div className={styles.projectsControllers}>
                    <div className={styles.filters}>
                        <div className={styles.searchfield}>
                            <CustomTextInputWithIcon
                                type="text"
                                placeholder="Search event"
                                className="search-input"
                                value=""
                                onChange={() => {}}
                                icon={MdSearch}
                                width="100%"
                            />
                        </div>
                        <span className={styles.btnFilter}>
                            <Dropdown>
                                <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className={styles.toggleBtn}
                                >
                                    <ButtonWithIcon
                                        onClick={() => console.log("filter")}
                                        icon={IoFilter}
                                        label="Filter"
                                    />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={styles.dropdownMenu}>
                                    <Dropdown.Item href="#/action-1" className={styles.dropdownItem}>
                                        Priority
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2" className={styles.dropdownItem}>
                                        Due Date
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3" className={styles.dropdownItem}>
                                        Status
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3" className={styles.dropdownItem}>
                                        Label
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </span>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <h1>My Activities</h1>
                {["Todo", "In Progress", "In Review", "Done"].map((header, index) => (
                    <div key={index} className="list-container">
                        <ListItems
                            header={header}
                            titles={itemsHeadings}
                            items={listItems}
                            labelColor="#fff"
                            labelBackgroundColor={header === "Todo" ? "#ccc" : "blue"}
                        />
                       
                    </div>
                ))}
            </div>
        </DashboardLayout>
    );
};

export default MyActivities;
