import React, { useState } from "react";
import Column from "./column";
import AddColumn from "./addcolumn";
import BurnBarrel from "./burncard";

const BoardView = ({ columns, setColumns }) => {
  const [cards, setCards] = useState(DEFAULT_CARDS);

  return (
    <div className="flex h-full gap-3 bg-white overflow-scroll p-12">
      {columns.map((column) => (
        <Column
          key={column.id}
          title={column.title}
          column={column.id}
          headingColor={column.headingColor}
          cards={cards}
          setCards={setCards}
        />
      ))}
      <div className="w-48">
        <AddColumn setColumns={setColumns} />
      </div>
      <div className="w-48">
        <BurnBarrel setCards={setCards} />
      </div>
    </div>
  );
};

export default BoardView;

const DEFAULT_CARDS = [
  {
    id: "1",
    title: "Card 1",
    description: "This is the description for card 1.",
    labels: ["label1", "label2"],
    message: "Message 1",
    users: ["user1", "user2"], // User IDs
    column: "backlog",
  },
  {
    id: "2",
    title: "Card 2",
    description: "This is the description for card 2.",
    labels: ["label3"],
    message: "Message 2",
    users: ["user3"], // User IDs
    column: "todo",
  },
  {
    id: "3",
    title: "Card 3",
    description: "This is the description for card 3.",
    labels: ["label1", "label4"],
    message: "Message 3",
    users: ["user4"], // User IDs
    column: "doing",
  },
  {
    id: "4",
    title: "Card 4",
    description: "This is the description for card 4.",
    labels: ["label2", "label5"],
    message: "Message 4",
    users: ["user1", "user4"], // User IDs
    column: "done",
  },
];
