import React, { useState } from "react";
import AuthLayout from "../../../layouts/auth/AuthLayout";
import CustomTextInput from "../../../components/inputs/textInput/CustomTextInput";
import CustomButton from "../../../components/inputs/button/CustomButton";
import styles from "./ForgotPassword.module.css";

const ForgotPassword = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");

  const handleFullNameChange = (e) => setFullName(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Full Name:", fullName);
    console.log("Email:", email);
    
  };

  return (
    <AuthLayout
      imageComponent={
        <img
        src="/images/forgot-password-image.png"
        alt="Forgot Password"
        className={styles.forgotPasswordImage}
      />
      }
      contentComponent={
        <form onSubmit={handleSubmit} className={styles.forgotPasswordForm}>
          <img src="/images/logo/WorkNOVA.png" alt="Logo" />
          <h3>Forgot Password</h3>
          <p>We'll send you a recovery password through email.</p>

          <div className={styles.formInput}>
            <CustomTextInput
              label="Full Name"
              placeholder="Enter your full name"
              value={fullName}
              onChange={handleFullNameChange}
              width="100%"
            />
          </div>

          <div className={styles.formInput}>
            <CustomTextInput
              label="Email"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
              width="100%"
              className={styles.formInput}
            />
          </div>

          <div className={styles.btn}>
            <CustomButton
              type="submit"
              variant="primary"
              size="small"
              customWidth="100%"
            >
              Continue
            </CustomButton>
          </div>
        </form>
      }
    />
  );
};

export default ForgotPassword;
