import React, { useState } from "react";
import CustomPasswordInput from "../../../components/inputs/passwordInput/CustomPasswordInput";
import CustomButton from "../../../components/inputs/button/CustomButton";
import AuthLayout from "../../../layouts/auth/AuthLayout";
import styles from "./RecoverPassword.module.css";

const RecoverPassword = () => {
  const [recoveryCode, setRecoveryCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isCodeValid, setIsCodeValid] = useState(false);

  const handleRecoveryCodeChange = (e) => setRecoveryCode(e.target.value);
  const handleNewPasswordChange = (e) => setNewPassword(e.target.value);
  const handleConfirmNewPasswordChange = (e) => setConfirmNewPassword(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Example code validation logic
    if (recoveryCode === "123456") { 
      setIsCodeValid(true);
    } else {
      alert("Invalid recovery code");
    }
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      alert("Passwords do not match!");
      return;
    }
    console.log('New Password:', newPassword);
    console.log('Confirm New Password:', confirmNewPassword);
    // Add logic to handle new password submission
  };

  const imageComponent = (
    <div className={styles.imageContainer}>
      <img src="/images/recovery-image.png" alt="Recovery" className={styles.recoveryImage} />
    </div>
  );

  const formComponent = (
    <div className={styles.formContainer}>
      <img src="/images/logo/workNOVA.png" alt="WorkNOVA Logo" className={styles.logo} /> 
      {!isCodeValid ? (
        <form onSubmit={handleSubmit} className={styles.recoveryPasswordForm}>
          <h3>Recovery Password</h3>
          <p>Input recovery password sent to your email.</p>

          <CustomPasswordInput
            label="Recovery Code"
            placeholder="Enter your recovery code"
            value={recoveryCode}
            onChange={handleRecoveryCodeChange}
            className={styles.inputField}
            width="100%"
          />

          <CustomButton type="submit" variant="primary" size="small"  customWidth="100%">
            Continue
          </CustomButton>
        </form>
      ) : (
        <form onSubmit={handlePasswordSubmit} className={styles.recoveryPasswordForm}>
          
          <h3>Set New Password</h3>

          <CustomPasswordInput
            label="New Password"
            placeholder="Enter your new password"
            value={newPassword}
            onChange={handleNewPasswordChange}
            className={styles.inputField}
            width="100%"
          />

          <CustomPasswordInput
            label="Confirm New Password"
            placeholder="Retype your new password"
            value={confirmNewPassword}
            onChange={handleConfirmNewPasswordChange}
            className={styles.inputField}
            width="100%"
          />

          <CustomButton type="submit" variant="primary" size="small"  customWidth="100%" >
            Reset Password
          </CustomButton>
        </form>
      )}
    </div>
  );

  return (
    <AuthLayout
      imageComponent={imageComponent}
      contentComponent={formComponent}
    />
  );
};




export default RecoverPassword;
