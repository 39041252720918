import React from "react";
import './Signup.css';
import CustomTextInput from "../../../components/inputs/textInput/CustomTextInput";
import CustomPasswordInput from "../../../components/inputs/passwordInput/CustomPasswordInput";
import CustomButton from "../../../components/inputs/button/CustomButton";
import AuthLayout from "../../../layouts/auth/AuthLayout";
import { Link } from 'react-router-dom';

const Signup = () => {
  return (
    <div className="signup-page">
      <AuthLayout
      imageComponent={
        <div className="column left">
          <img src="/images/frame25.png" alt="Image" />
        </div>
      }

      contentComponent={
        <div className="column right">
          <form className="login-form">
            <h1>Work<span>Nova</span></h1>
            <h3>Get Started</h3>
            <p>Effectively manage your team with ease</p>
            <div className="fullName">
              <CustomTextInput label="Full Name" placeholder={"Enter your full name"} />
            </div>
            <div className="emailField">
              <CustomTextInput label="Email" placeholder={"Enter your email"} />
            </div>
            <div className="passwordField">
              <CustomPasswordInput label="Password" placeholder={"Enter your password"} />
            </div>
            <div className="confirmPassword">
              <CustomPasswordInput label="Confirm Password" placeholder={"Retype your password"} />
            </div>
            <div className="termsOfService">
              <label>
                <input type="checkbox"/>I agree to the <a href="#">Terms of Service</a> and <a href="#">Privacy Policies</a>
              </label>
            </div>
            <div className="signup-button">
              <CustomButton size={"small"}>Create my account</CustomButton>
              <div className="break"></div>
              <CustomButton variant={"outlined"} size={"small"}>Sign up with Google</CustomButton>
            </div>
            <div className="login">
              <p>Already have an account? <Link to={"/login"}>Log In</Link></p>
            </div>
          </form>
        </div>
      }
      />
    </div>
  );
};

export default Signup;
