// CustomButton.js
import React from "react";
import PropTypes from "prop-types";
// import { FaList } from "react-icons/fa";
import styles from "./ButtonWithIcon.module.css";

const ButtonWithIcon = ({ onClick, isActive, icon: Icon, label }) => {
  return (
    <button
      onClick={onClick}
      style={{
        backgroundColor: isActive ? "#0d6efd" : "transparent",
        color: isActive ? "#ffffff" : "#000",
      }}
      className={styles.custom_button}
    >
      {Icon && <Icon size={15} className={styles.icon} />} <span>{label}</span>
    </button>
  );
};

ButtonWithIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  icon: PropTypes.elementType,
  label: PropTypes.string.isRequired,
};

ButtonWithIcon.defaultProps = {
  isActive: false,
  //   icon: FaList,
};

export default ButtonWithIcon;
