import React from "react";
import styles from "./Categories.module.css";
import { FaPlus } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import ButtonWithIcon from "../../btnWithIcon/ButtonWithIcon";
import { BsThreeDots } from "react-icons/bs";
import { Link } from "react-router-dom";

const Categories = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.headings}>
        <h5 className={styles.heading1}>Category Name</h5>
        <h5>Category Supervisor</h5>
        <h5>Date Created</h5>
        <h5>Actions</h5>
      </div>

      <div className={styles.section}>
        {[1, 1, 1, 1].map((item, index) => {
          return (
            <div className={styles.items} key={index}>
              <div className={styles.item1}>
                <p>WorkNova</p>
              </div>
              <div className={styles.item}>
                <img src="/images/Frame1.png" about="profile image" />
              </div>
              <div className={styles.item}>
                <p>05/12/2024</p>
              </div>
              <div className={styles.item}>
                <span className={styles.btnFilter}>
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className={styles.toggleBtn}
                    >
                      <ButtonWithIcon
                        onClick={() => console.log("filter")}
                        icon={BsThreeDots}
                        label=""
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={styles.dropdownMenu}>
                      <Dropdown.Item className={styles.dropdownItem}>
                        <Link to="/categories/edit">Edit</Link>
                      </Dropdown.Item>
                      <Dropdown.Item className={styles.dropdownItem}>
                        <Link to="/categories/projects">View Projects</Link>
                      </Dropdown.Item>
                      <Dropdown.Item className={styles.dropdownItem}>
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </span>
              </div>
            </div>
          );
        })}

        <div className={styles.addCard}>
          <button>
            <FaPlus /> <span>Add Category</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Categories;
