import React from "react";
import DashboardLayout from "../../../../layouts/dashboard/DashboardLayout";
import CustomTextInput from "../../../../components/inputs/textInput/CustomTextInput";
import styles from "./EventName.module.css";

import { LuCalendarClock, LuDownload } from "react-icons/lu";
import { IoIosAttach,IoIosAdd  } from "react-icons/io";
import {FaRegTrashAlt } from "react-icons/fa";


// Hello Sir, This is to be under "My Schedule".  

const EventName = () => {
    return (
        <DashboardLayout className={styles.wrapper}>
          <h3>Event Name</h3>
          <div className={styles.details}>
            <div className={styles.forms}>
            <div className={styles.item}>
              <div className={styles.title}>Project</div>
              <div className={styles.value}>
                <select>
                  <option>Work Nova</option>
                  <option>Work Nova</option>
                </select>
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.title}>Location</div>
              <div className={styles.value}>
                <select>
                  <option>Google Meet</option>
                  <option>Zoom</option>
                </select>
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.title}>Link / Venue</div>
              <div className={styles.value}>
                <span>google meeting link</span>
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.title}>Event Date</div>
              <div className={styles.value}>
                <LuCalendarClock size={20} /> <span>12/05/2024</span>
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.title}>Time</div>
              <div className={styles.value}>
                <select>
                  <option>Google Meet</option>
                </select>
              </div>
            </div>

            <div className={styles.comments}>
              <p>Comments</p>
             <div className={styles.commemtInput}>
              <img src="/images/Frame4.png" />
              <div className={styles.input}>
                <CustomTextInput
                  value=""
                  onChange={() => console.log("input")}
                  placeholder="Comment"
                />
              </div>
              </div>
        
              <div className={styles.commemtInput}>
              <img src="/images/Frame4.png" />
              <div className={styles.input}>
                <CustomTextInput
                  value=""
                  onChange={() => console.log("input")}
                  placeholder="Comment"
                />
              </div>
            </div>
          
              
               </div>   
              </div>

            <div className={styles.rightSection}>
              <div className={styles.roles}>
                <h4>Attendees</h4>
                <div className={styles.settings}>
                  <div className={styles.item}>
                    <div className={styles.value}>
                      <img src="/images/Frame1.png" />
                      <div>John Doe</div>
                    </div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.value}>
                      <img src="/images/Frame1.png" />
                      <div>John Doe</div>
                    </div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.value}>
                      <img src="/images/Frame1.png" />
                      <div>John Doe</div>
                    </div>
                  </div>
          
            <div className={styles.btn}>
              <button>
                <IoIosAdd size={15} /> Add Role
              </button>
            </div>
           </div>
          </div>
    
               
          <div className={styles.attachments}>
            <h4>Attachments</h4>

            <div className={styles.attachmentItems}>
              {[1,1].map((item, index) => {
                return (
                  <div className={styles.card} key={index}>
                    <div className={styles.cardTop}>
                      <button>
                        <LuDownload />
                      </button>{" "}
                      <button>
                        <FaRegTrashAlt />
                      </button>
                    </div>
                    <div className={styles.imgDiv}>
                      <img src="/images/Frame5.png" />
                    </div>
                    <div className={styles.cardBottom}>attachment.png</div>
                  </div>
                );
              })}
            </div>

            <div className={styles.btn}>
              <button>
                <IoIosAttach size={20} /> Attach a file
              </button>
            </div>
          </div>
            </div>
          </div>
        </DashboardLayout>
      );
    };
    
    export default EventName;
    
