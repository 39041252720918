import React from "react";
import DashboardLayout from "../../../../layouts/dashboard/DashboardLayout";
import styles from "./CategoryDetails.module.css";
import CustomTextarea from "../../../../components/inputs/textareaInput/CustomTextarea";
import CustomTextInput from "../../../../components/inputs/textInput/CustomTextInput";
import { Dropdown } from "react-bootstrap";
import ButtonWithIcon from "../../../../components/btnWithIcon/ButtonWithIcon";
import { Link } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";

const CategoryDetails = () => {
  return (
    <DashboardLayout>
      <div className={styles.wrapper}>
        <div className={styles.categoryTitle}>
          <h3>Category Name</h3>
          <span className={styles.btnFilter}>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic" className={styles.toggleBtn}>
                <ButtonWithIcon
                  onClick={() => console.log("filter")}
                  icon={BsThreeDots}
                  label=""
                />
              </Dropdown.Toggle>

              <Dropdown.Menu className={styles.dropdownMenu}>
                <Dropdown.Item className={styles.dropdownItem}>
                  <Link to="#">Projects</Link>
                </Dropdown.Item>
                <Dropdown.Item className={styles.dropdownItem}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </div>
        <div className={styles.supervisor}>
          <div>Category Supervisor</div>
          <div className={styles.supervisorName}>
            <img src="/images/Frame1.png" />
            <div>John Doe</div>
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles.forms}>
            <CustomTextarea
              label="Category Description"
              value=""
              onChange={() => console.log("hello")}
              placeholder="Description"
            />
            <div className={styles.comments}>
              <p>Comments</p>
              <div className={styles.commemtInput}>
                <img src="/images/Frame1.png" />
                <div className={styles.input}>
                  <CustomTextInput
                    value=""
                    onChange={() => console.log("input")}
                    placeholder="Comment"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default CategoryDetails;
