import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import styles from "./AuthLayout.module.css";

const AuthLayout = ({ imageComponent, contentComponent }) => {
  const { authenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!authenticated) {
  //     navigate("/");
  //     return;
  //   }
  // }, [authenticated, navigate]);

  return (
    <div className={["container", styles.wrapper].join(" ")}>
      <div className={styles.imageSection}>{imageComponent}</div>
      <div className={styles.contentSection}>{contentComponent}</div>
    </div>
  );
};

export default AuthLayout;
