import React, { useState } from "react";
import styles from "./styles.module.css";
import DashboardLayout from "../../../layouts/dashboard/DashboardLayout";
import Header from "./TimeTrackingHeader/Header";
import {
  FaLongArrowAltRight,
  FaPlay,
  FaRegTrashAlt,
  FaPlus,
} from "react-icons/fa";
import { LuTimerReset } from "react-icons/lu";
import { CgSandClock } from "react-icons/cg";
import DatePickerIcon from "../../../components/inputs/DatePicker/DatePicker";
import ModalComponent from "../../../components/Modal/Modal";

const TimeTracking = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  return (
    <DashboardLayout>
      <div className={styles.wrapper}>
        <Header />
        <div className={styles.timeTracker}>
          <h3>Time Tracker</h3>
          <div className={styles.dataPagination}>
            <div>1 July - 7 July</div>
            <div className={styles.icon}>
              <DatePickerIcon />
            </div>
          </div>

          <div className={styles.contentWrapper}>
            <div className={styles.leftContent}>
              {[1, 1, 1, 1, 1, 1].map((iten, index) => {
                return (
                  <div className={styles.dateTracker}>
                    <div className={styles.dateSection}>
                      <div>
                        <div className={styles.day}>Monday July 1</div>
                        <div className={styles.hours}>10h 23m 51s</div>
                        <div className={styles.action} onClick={handleShow}>
                          Edit <FaLongArrowAltRight />
                        </div>
                      </div>
                    </div>
                    <div className={styles.timeSection}>
                      {[1, 1, 1].map((item, index) => {
                        return (
                          <div className={styles.item}>
                            <div className={styles.data}>
                              <div className={styles.icon}>
                                <LuTimerReset />
                              </div>
                              <div className={styles.time}>
                                09:31 am - 02:35 pm
                              </div>
                            </div>
                            <div className={styles.data}>
                              <div className={styles.icon}>
                                <CgSandClock />
                              </div>
                              <div className={styles.time}>
                                09:31 am - 02:35 pm
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={styles.rightContent}>
              <div className={styles.counter}>
                <div className={styles.hours}>
                  <div>
                    <div className={styles.icon}>
                      <FaPlay color="#fff" />
                    </div>
                    <div className={styles.item}>
                      <p>Today</p>
                      <h3>05h 47m 31s</h3>
                    </div>
                    <div className={styles.item}>
                      <p>This Week</p>
                      <h3>28h 51m 05s</h3>
                    </div>
                    <div className={styles.item}>
                      <p>This Month</p>
                      <h3>105h 47m 31s</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalComponent title="Tuesday July 2" show={show} setShow={setShow}>
          <div className={styles.modalBody}>
            <div className={styles.data}>
              <h5>Recorded Time</h5>
              {[1, 1, 1, 1, 1, 1, 1, 1].map((data, index) => {
                return (
                  <div key={index}>
                    <div className={styles.timer}>
                      <div className={styles.timerSet}>
                        <div className={styles.icon}>
                          <LuTimerReset />
                        </div>
                        <div className={styles.time}>09:31 am - 02:35 pm</div>
                      </div>
                      <div className={styles.timerSet}>
                        <div className={styles.icon}>
                          <CgSandClock />
                        </div>
                        <div>05h 04m 05s</div>
                      </div>
                    </div>
                    <div className={styles.tasks}>
                      {[1, 1, 1, 1, 1, 1, 1].map((item, index) => {
                        return (
                          <div className={styles.taskItem} key={index}>
                            <div className={styles.detail}>
                              Create a new landing page for campagne
                            </div>
                            <div className={styles.icon}>
                              <FaRegTrashAlt />
                            </div>
                          </div>
                        );
                      })}
                      <div className={styles.addCard}>
                        <button>
                          <FaPlus /> Add a card
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </ModalComponent>
      </div>
    </DashboardLayout>
  );
};

export default TimeTracking;
