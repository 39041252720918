import React from "react";
import styles from "./Issues.module.css";
import ListItems from "../../listItems/ListItems";

const Issues = () => {
  const itemsHeadings = [
    "Task Name",
    "Assignee",
    "Label",
    "Due Date",
    "Action",
  ];
  const listItems = [
    {
      taskName:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos cumque quo est voluptatum, amet numquam odit consequuntur sed porro mollitia. Nobis ratione vero repellat animi non? Iure deserunt itaque illo.",
      assignee: "/images/Frame1.png",
      label: "Label Name",
      dueDate: "05/12/2024",
    },
    {
      taskName:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos cumque quo est voluptatum, amet numquam odit consequuntur sed porro mollitia. Nobis ratione vero repellat animi non? Iure deserunt itaque illo.",
      assignee: "/images/Frame1.png",
      label: "Label Name",
      dueDate: "05/12/2024",
    },
    {
      taskName:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos cumque quo est voluptatum, amet numquam odit consequuntur sed porro mollitia. Nobis ratione vero repellat animi non? Iure deserunt itaque illo.",
      assignee: "/images/Frame1.png",
      label: "Label Name",
      dueDate: "05/12/2024",
    },
    {
      taskName:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos cumque quo est voluptatum, amet numquam odit consequuntur sed porro mollitia. Nobis ratione vero repellat animi non? Iure deserunt itaque illo.",
      assignee: "/images/Frame1.png",
      label: "Label Name",
      dueDate: "05/12/2024",
    },
    {
      taskName:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos cumque quo est voluptatum, amet numquam odit consequuntur sed porro mollitia. Nobis ratione vero repellat animi non? Iure deserunt itaque illo.",
      assignee: "/images/Frame1.png",
      label: "Label Name",
      dueDate: "05/12/2024",
    },
  ];
  return (
    <div className={styles.wrapper}>
      <ListItems
        header="Todo"
        titles={itemsHeadings}
        items={listItems}
        labelColor="#fff"
        labelBackgroundColor="#ccc"
      />
      <ListItems
        header="In Progress"
        // titles={itemsHeadings}
        items={listItems}
        labelColor="#fff"
        labelBackgroundColor="blue"
      />
      <ListItems
        header="In Review"
        // titles={itemsHeadings}
        items={listItems}
        labelColor="#fff"
        labelBackgroundColor="blue"
      />
      <ListItems
        header="Done"
        // titles={itemsHeadings}
        items={listItems}
        labelColor="#fff"
        labelBackgroundColor="blue"
      />
    </div>
  );
};

export default Issues;
