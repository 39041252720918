import React, { useState } from "react";
import DashboardLayout from "../../../layouts/dashboard/DashboardLayout";
import Calendar from "../calendar/Calendar";
import styles from "./Dashboard.module.css";
import {
  FaPlay,
  FaLongArrowAltRight,
  FaChevronRight,
  FaClock,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { PiMapPinAreaFill } from "react-icons/pi";
import { LuCalendarClock } from "react-icons/lu";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import LineChart from "../../../components/charts/Line/LineChart";

const projectsData = [
  {
    categoryName: "WorkNova",
    manager: "/images/Frame1.png",
    priority: "medium",
    endDate: "12/02/2015",
    tshirtSize: "XL",
  },
  {
    categoryName: "WorkNova",
    manager: "/images/Frame1.png",
    priority: "medium",
    endDate: "12/02/2015",
    tshirtSize: "XL",
  },
  {
    categoryName: "WorkNova",
    manager: "/images/Frame1.png",
    priority: "medium",
    endDate: "12/02/2015",
    tshirtSize: "XL",
  },
  {
    categoryName: "WorkNova",
    manager: "/images/Frame1.png",
    priority: "medium",
    endDate: "12/02/2015",
    tshirtSize: "XL",
  },
  {
    categoryName: "WorkNova",
    manager: "/images/Frame1.png",
    priority: "medium",
    endDate: "12/02/2015",
    tshirtSize: "XL",
  },
];

const tasksData = [
  {
    tasksName:
      "Create terms of service and privacy policy for the worknova project.",
    assignee: "/images/Frame1.png",
    label: "Worknova",
    dueDate: "12/02/2015",
  },
  {
    tasksName:
      "Create terms of service and privacy policy for the worknova project.",
    assignee: "/images/Frame1.png",
    label: "Worknova",
    dueDate: "12/02/2015",
  },
  {
    tasksName:
      "Create terms of service and privacy policy for the worknova project.",
    assignee: "/images/Frame1.png",
    label: "Worknova",
    dueDate: "12/02/2015",
  },
  {
    tasksName:
      "Create terms of service and privacy policy for the worknova project.",
    assignee: "/images/Frame1.png",
    label: "Worknova",
    dueDate: "12/02/2015",
  },
  {
    tasksName:
      "Create terms of service and privacy policy for the worknova project.",
    assignee: "/images/Frame1.png",
    label: "Worknova",
    dueDate: "12/02/2015",
  },
];

const Dashboard = () => {
  const [projects, setProjects] = useState(projectsData);
  const [tasks, setTasks] = useState(tasksData);

  return (
    <DashboardLayout>
      <div className={styles.wrapper}>
        <div className={styles.summary}>
          <h3>Summary</h3>
          <div className={styles.statistics}>
            <div className={styles.statItem}>
              <p>Hours Worked Today</p>
              <h3>02h 34m 19s</h3>
            </div>
            <div className={styles.statItem}>
              <p>Projects</p>
              <h3>04</h3>
            </div>
            <div className={styles.statItem}>
              <p>Current Tasks</p>
              <h3>08</h3>
            </div>
            <div className={styles.statItem}>
              <p>Deadlines</p>
              <h3>10</h3>
            </div>
            <div className={[styles.statItem, styles.eventStat].join(" ")}>
              <p>Events This Week</p>
              <h3>41</h3>
            </div>
          </div>
        </div>

        <div className={styles.projects}>
          <div className={styles.hours}>
            <div>
              <div className={styles.icon}>
                <FaPlay color="#397CFB" />
              </div>
              <div className={styles.item}>
                <p>Today</p>
                <h3>05h 47m 31s</h3>
              </div>
              <div className={styles.item}>
                <p>This Week</p>
                <h3>28h 51m 05s</h3>
              </div>
              <div className={styles.item}>
                <p>This Month</p>
                <h3>105h 47m 31s</h3>
              </div>
              <div className={styles.viewall}>
                <Link>
                  <span>View all</span> <FaLongArrowAltRight />
                </Link>
              </div>
            </div>
          </div>

          <div className={styles.listProjects}>
            <h3>Projects</h3>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Category Name</th>
                  <th>Project Manager</th>
                  <th>Priority</th>
                  <th>End Date</th>
                  <th>T-Shirt Size</th>
                </tr>
              </thead>
              <tbody>
                {projects.map((record, index) => (
                  <tr key={index}>
                    <td>{record.categoryName}</td>
                    <td>
                      <img src={record.manager} />
                    </td>
                    <td>
                      <div className={styles.priority}>
                        <HiOutlineMenuAlt4 color="#FDC532" />{" "}
                        <span>{record.priority}</span>
                      </div>
                    </td>
                    <td>{record.endDate}</td>
                    <td>{record.tshirtSize}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className={styles.listAll}>
              <Link to="#">
                <span>View all</span> <FaLongArrowAltRight />
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.lineChart}>
          <h3>My Burn-down Chart</h3>
          <LineChart />
        </div>

        <div className={styles.listTasks}>
          <h3>Tasks In Progress</h3>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Task Name</th>
                <th>Assignee</th>
                <th>Label</th>
                <th>Due Date</th>
              </tr>
            </thead>
            <tbody>
              {tasks.map((record, index) => (
                <tr key={index}>
                  <td>{record.tasksName}</td>
                  <td>
                    <img src={record.assignee} />
                  </td>
                  <td>
                    <span className={styles.label}>{record.label}</span>
                  </td>
                  <td>{record.dueDate}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={styles.listAll}>
            <Link to="#">
              <span>View all</span> <FaLongArrowAltRight />
            </Link>
          </div>
        </div>

        <div className={styles.calenderAndEvents}>
          <div className={styles.card1}>
            <h3>My Calendar</h3>
            <div className={styles.calenderItems}>
              {[1, 1, 1].map((item, index) => {
                return (
                  <div className={styles.items} key={index}>
                    <div className={styles.item}>
                      <div>Task Name</div>
                      <div>Project Name</div>
                    </div>
                    <div className={styles.item}>
                      <div></div>
                      <div className={styles.flexDiv}>
                        <LuCalendarClock /> <span>12/01/2022</span>
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div></div>
                      <div className={styles.flexDiv}>
                        <HiOutlineMenuAlt4 color="#FDC532" />{" "}
                        <span>medium</span>
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div></div>
                      <div>
                        <select>
                          <option value="">Todo</option>
                          <option value="">In Progress</option>
                          <option value="">Under Review</option>

                          <option value="">Done</option>
                        </select>
                      </div>
                    </div>
                    <div className={styles.item}>
                      <FaChevronRight size={20} />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={styles.listAll}>
              <Link to="#">
                <span>View all</span> <FaLongArrowAltRight />
              </Link>
            </div>
          </div>
          <div className={styles.card2}>
            <h3>Upcoming Events</h3>
            <div className={styles.eventsItems}>
              {[1, 1, 1].map((item, index) => {
                return (
                  <div className={styles.item} key={index}>
                    <div className={styles.details}>
                      <div className={styles.top}>
                        Create terms of service and privacy policy for the
                        worknova project.
                      </div>
                      <div className={styles.bottom}>
                        <div>Project Name</div>
                        <div className={styles.flexDiv}>
                          <FaClock /> <span>05:00 PM</span>
                        </div>
                        <div className={styles.flexDiv}>
                          <PiMapPinAreaFill size={20} />{" "}
                          <span>Google Meet</span>
                        </div>
                      </div>
                    </div>
                    <div className={styles.itemIcon}>
                      <FaChevronRight size={20} />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={styles.listAll}>
              <Link to="#">
                <span>View all</span> <FaLongArrowAltRight />
              </Link>
            </div>
          </div>
        </div>
        <div>
          <Calendar />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
