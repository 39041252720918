import React, { useState } from "react";
import CustomTextInputWithIcon from "../../inputs/textInputWithIcon/CustomInputWithIcon";
import styles from "./TopNav.module.css";
import { MdSearch } from "react-icons/md";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

const TopNav = () => {
  const [text, setText] = useState("");

  const handleTextChange = (event) => {
    setText(event.target.value);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.welcome}>
        <p className={styles.title}>
          Welcome, <span>John</span>
        </p>
        <p className={styles.desc}>
          Effectively manage plan and execute your projects here.
        </p>
      </div>
      <div className={styles.searchDiv}>
        <CustomTextInputWithIcon
          value={text}
          onChange={handleTextChange}
          placeholder="Type to search..."
          icon={MdSearch}
          width="100%"
        />
        <span className={styles.btnFilter}>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className={styles.toggleBtn}>
              <img src="/images/Frame1.png" about="profile image" />
            </Dropdown.Toggle>

            <Dropdown.Menu className={styles.dropdownMenu}>
              <Dropdown.Item className={styles.dropdownItem}>
                <Link to="#">Profile</Link>
              </Dropdown.Item>
              <Dropdown.Item className={styles.dropdownItem}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </span>
      </div>
    </div>
  );
};

export default TopNav;
