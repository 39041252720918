import React, { useState, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./styles.module.css";
import { MdDateRange } from "react-icons/md";

const DatePickerIcon = () => {
  const [startDate, setStartDate] = useState(null);
  const datePickerRef = useRef(null);

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <label
        className={styles.datePickerIcon}
        onClick={handleIconClick}
        role="button"
        aria-label="Open date picker"
        style={{
          cursor: "pointer",
          fontSize: "24px",
          border: "none",
          background: "none",
        }}
      >
        <MdDateRange />
      </label>
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        ref={datePickerRef}
        popperPlacement="bottom-start"
        popperModifiers={{
          offset: {
            enabled: true,
            offset: "0, 10",
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "viewport",
          },
        }}
        customInput={<input type="text" style={{ display: "none" }} />}
      />
    </div>
  );
};

export default DatePickerIcon;
