import React, { useState } from "react";
import CalendarDays from "./CalendarDays";
import styles from "./Calendar.css";
import CustomTextInputWithIcon from "../../../components/inputs/textInputWithIcon/CustomInputWithIcon";
import { MdSearch } from "react-icons/md";

const Calendar = () => {
    const [currentDay, setCurrentDay] = useState(new Date());

    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 
                    'July', 'August', 'September', 'October', 'November', 'December'];
    
    const [text, setText] = useState("");

    const handleTextChange = (event) => {
        setText(event.target.value);
    };
    
    const handlePrevMonth = () => {
        setCurrentDay(new Date(currentDay.getFullYear(), currentDay.getMonth() - 1, 1));
    };

    const handleNextMonth = () => {
        setCurrentDay(new Date(currentDay.getFullYear(), currentDay.getMonth() + 1, 1));
    };

    return (
        <>
        <div className="breadcrumb">
            <p>Workspace / My Schedule / <b>My Calendar</b></p>
        </div>
        <div className="search-filter">
            <div className="search-bar">
                <CustomTextInputWithIcon 
                    // label="Search"
                    value={text}
                    onChange={handleTextChange}
                    placeholder="Search task"
                    icon={MdSearch}
                    width="100%"
                />
            </div>
        </div>
        <div className="calendar">
            <div className="calendar-header">
                <h2>My Calendar</h2>
            </div>
            <div className="date-view">
                <button onClick={handlePrevMonth} className="previous"><b>&lt;</b></button>
                <h3>{months[currentDay.getMonth()]} {currentDay.getFullYear()}</h3>
                <button onClick={handleNextMonth} className="next"><b>&gt;</b></button>
                <button className="monthly">Monthly View</button>
                <button className="weekly">Weekly View</button>
            </div>
            <div className="calendar-body">
                <div className="table-header">
                {
                    weekdays.map((weekday, index) => {
                    return <div key={index} className="weekday"><p>{weekday}</p></div>
                 })
                }
                </div>
                <div className="table">
                    <CalendarDays day={currentDay} changeCurrentDay={(day) => setCurrentDay(day)} />
                </div>
            </div>
        </div>
        </>
    );
};

export default Calendar;