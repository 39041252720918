import React from "react";
import DashboardLayout from "../../../../layouts/dashboard/DashboardLayout";
import styles from "./ProjectDetails.module.css";
import CustomTextarea from "../../../../components/inputs/textareaInput/CustomTextarea";
import CustomTextInput from "../../../../components/inputs/textInput/CustomTextInput";

import { LuCalendarClock, LuDownload } from "react-icons/lu";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { IoIosAttach } from "react-icons/io";
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";

const ProjectDetails = () => {
  return (
    <DashboardLayout className={styles.wrapper}>
      <h3>Create terms of service and privacy policy</h3>
      <p>Project Name</p>
      <div className={styles.details}>
        <div className={styles.forms}>
          <CustomTextarea
            label="Definition of Ready"
            value=""
            onChange={() => console.log("hello")}
            placeholder="Description"
          />
          <CustomTextarea
            label="Definition of Done"
            value=""
            onChange={() => console.log("hello")}
            placeholder="Acceptance Criteria"
          />
          <div className={styles.comments}>
            <p>Comments</p>
            <div className={styles.commemtInput}>
              <img src="/images/Frame1.png" />
              <div className={styles.input}>
                <CustomTextInput
                  value=""
                  onChange={() => console.log("input")}
                  placeholder="Comment"
                  // width="180%"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightSection}>
          <div className={styles.settings}>
            <div className={styles.item}>
              <div className={styles.title}>Priority</div>
              <div className={styles.value}>
                <HiOutlineMenuAlt4 size={20} color="#dbd221" />
                <span>Medium</span>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.title}>Due Date</div>
              <div className={styles.value}>
                <LuCalendarClock size={20} /> <span>12/05/2014</span>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.title}>T-Shirt Size</div>
              <div className={styles.value}>XL</div>
            </div>
          </div>

          <div className={styles.roles}>
            <h4>Project Roles</h4>
            <div className={styles.settings}>
              <div className={styles.item}>
                <div className={styles.title}>Project Manager</div>
                <div className={styles.value}>
                  <img src="/images/Frame1.png" />
                  <div>John Doe</div>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.title}>Scrum Master</div>
                <div className={styles.value}>
                  <img src="/images/Frame1.png" />
                  <div>John Doe</div>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.title}>Frontend Engineer</div>
                <div className={styles.value}>
                  <img src="/images/Frame1.png" />
                  <div>John Doe</div>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.title}>
                  <button>
                    <FaPlus size={15} /> Add Role
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.attachments}>
            <h4>Attachments</h4>

            <div className={styles.attachmentItems}>
              {[1, 1, 1, 1, 1].map((item, index) => {
                return (
                  <div className={styles.card} key={index}>
                    <div className={styles.cardTop}>
                      <button>
                        <LuDownload />
                      </button>{" "}
                      <button>
                        <FaRegTrashAlt />
                      </button>
                    </div>
                    <div className={styles.imgDiv}>
                      <img src="/images/Frame1.png" />
                    </div>
                    <div className={styles.cardBottom}>attachment.png</div>
                  </div>
                );
              })}
            </div>

            <div className={styles.btn}>
              <button>
                <IoIosAttach size={20} /> Attach a file
              </button>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ProjectDetails;
