import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import './AddEventModal.css';
import CustomTextInput from '../inputs/textInput/CustomTextInput';
import { LuCalendarClock, LuDownload } from 'react-icons/lu';
import { FaRegTrashAlt } from 'react-icons/fa';
import { IoIosAttach } from 'react-icons/io';

const CustomModal = ({ isOpen, closeModal, addEvent, eventState, handleChange, projects, locations }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Add Event Modal"
            className="ReactModal__Content"
            overlayClassName="ReactModal__Overlay"
        >
            <form className="modal-form">
                <div className="modal-left">
                    <h2>Event Name</h2>
                    <div className="form-group-container">
                        <div className="form-group">
                            <label>Project</label>
                            <Dropdown
                                className="dropdown"
                                onSelect={(e) => handleChange({ target: { name: 'project', value: e } })}
                            >
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    {eventState.project || 'Work Nova'}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {projects.map((project, index) => (
                                        <Dropdown.Item key={index} eventKey={project}>
                                            {project}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="form-group">
                            <label>Location</label>
                            <Dropdown
                                onSelect={(e) => handleChange({ target: { name: 'location', value: e } })}
                            >
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    {eventState.location || 'Google Meet'}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {locations.map((location, index) => (
                                        <Dropdown.Item key={index} eventKey={location}>
                                            {location}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="form-group">
                            <label>Link / Venue</label>
                            <p>google meeting link</p>
                        </div>
                        <div className="form-group">
                            <label>Event Date</label>
                            <div className="event-date-container">
                                <LuCalendarClock id="calendar-date" /> <p>12/05/2024</p>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Time</label>
                            <Dropdown
                                onSelect={(e) => handleChange({ target: { name: 'time', value: e } })}
                            >
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    {eventState.time || 'Google Meet'}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {locations.map((location, index) => (
                                        <Dropdown.Item key={index} eventKey={location}>
                                            {location}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="comment-section">
                        <h3>Comments</h3>
                        {[1, 2].map((item, index) => (
                            <div className="comment-input" key={index}>
                                <img src="/images/Frame4.png" alt="icon" />
                                <div className="input">
                                    <CustomTextInput
                                        value={eventState.comment}
                                        onChange={handleChange}
                                        placeholder="Comment"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="modal-right">
                    <div className="attendees">
                        <h3>Attendees</h3>
                        <div className="attendee-list">
                            {[1, 2, 3].map((item, index) => (
                                <div className="attendee" key={index}>
                                    <img src={`/images/attendee${index + 1}.png`} alt="attendee" />
                                    <span>John Doe</span>
                                </div>
                            ))}
                            <button type="button" className="add-attendee-button">
                                <img src="/images/add.png" alt="add icon" id="add_icon" />
                                Add role
                            </button>
                        </div>
                    </div>
                    <div className="attachments">
                        <h3>Attachments</h3>
                        <div className="attachment-items">
                            {[1, 2].map((item, index) => (
                                <div className="card" key={index}>
                                    <div className="card-top">
                                        <button>
                                            <LuDownload />
                                        </button>
                                        <button>
                                            <FaRegTrashAlt />
                                        </button>
                                    </div>
                                    <div className="img-div">
                                        <img src="/images/Frame5.png" alt="attachment" />
                                    </div>
                                    <div className="card-bottom">Project Instructions.jpg</div>
                                </div>
                            ))}
                        </div>
                        <button className="attachment-btn">
                            <IoIosAttach size={20} /> Attach a file
                        </button>
                    </div>
                </div>
                {/* <div className="modal-buttons">
                    <button type="button" onClick={addEvent} className="add-event-btn">Add Event</button>
                    <button type="button" onClick={closeModal} className="close-modal-btn">Close</button>
                </div> */}
            </form>
        </Modal>
    );
};

CustomModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    addEvent: PropTypes.func.isRequired,
    eventState: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    projects: PropTypes.array.isRequired,
    locations: PropTypes.array.isRequired,
};

export default CustomModal;
