import React, { useState } from "react";
import BoardView from "./components/board";

const KanbanBoard = () => {
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);

  return (
    <div className="h-screen w-full bg-white text-neutral-50">
      <BoardView columns={columns} setColumns={setColumns} />
    </div>
  );
};

export default KanbanBoard;

const DEFAULT_COLUMNS = [
  { id: "backlog", title: "Backlog", headingColor: "text-neutral-500" },
  { id: "todo", title: "TODO", headingColor: "text-yellow-200" },
  { id: "doing", title: "In progress", headingColor: "text-blue-200" },
  { id: "done", title: "Complete", headingColor: "text-emerald-200" },
];
