import React, { useState } from "react";
import { FiPlus } from "react-icons/fi";
import { motion } from "framer-motion";
import ReactDOM from "react-dom";

// Default user objects
const defaultUsers = [
  {
    id: "user1",
    name: "Alice",
    imageUrl: "https://via.placeholder.com/30?text=A",
  },
  {
    id: "user2",
    name: "Bob",
    imageUrl: "https://via.placeholder.com/30?text=B",
  },
  {
    id: "user3",
    name: "Charlie",
    imageUrl: "https://via.placeholder.com/30?text=C",
  },
  {
    id: "user4",
    name: "David",
    imageUrl: "https://via.placeholder.com/30?text=D",
  },
];

// Modal Component
const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <motion.div
        initial={{ y: "-50vh" }}
        animate={{ y: "0" }}
        exit={{ y: "-50vh" }}
        className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </motion.div>
    </motion.div>,
    document.body
  );
};

// AddCard Component
const AddCard = ({ column, setCards }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [labels, setLabels] = useState("");
  const [message, setMessage] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleUserChange = (e) => {
    const { value, checked } = e.target;
    setSelectedUsers((prev) =>
      checked ? [...prev, value] : prev.filter((user) => user !== value)
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!title.trim().length) return;

    const newCard = {
      column,
      title: title.trim(),
      description: description.trim(),
      labels: labels.split(",").map((label) => label.trim()),
      message: message.trim(),
      selectedUsers,
      id: Math.random().toString(),
    };

    setCards((pv) => [...pv, newCard]);
    setModalOpen(false); // Close the modal after adding the card
  };

  return (
    <>
      <button
        onClick={() => setModalOpen(true)}
        className="flex w-full items-center gap-1.5 px-3 py-1.5 text-sm text-gray-600 hover:text-gray-800"
      >
        <span>Add card</span>
        <FiPlus />
      </button>

      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <h3 className="text-lg font-bold mb-4">Add New Card</h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="block text-gray-700 mb-1">Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full rounded border border-gray-300 p-2"
              required
            />
          </div>
          <div className="mb-3">
            <label className="block text-gray-700 mb-1">Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full rounded border border-gray-300 p-2"
            />
          </div>
          <div className="mb-3">
            <label className="block text-gray-700 mb-1">
              Labels (comma separated)
            </label>
            <input
              type="text"
              value={labels}
              onChange={(e) => setLabels(e.target.value)}
              className="w-full rounded border border-gray-300 p-2"
            />
          </div>
          <div className="mb-3">
            <label className="block text-gray-700 mb-1">Message</label>
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full rounded border border-gray-300 p-2"
            />
          </div>
          <div className="mb-3">
            <label className="block text-gray-700 mb-1">Assign Users</label>
            <div className="flex flex-col gap-2">
              {defaultUsers.map((user) => (
                <label key={user.id} className="flex items-center">
                  <input
                    type="checkbox"
                    value={user.id}
                    checked={selectedUsers.includes(user.id)}
                    onChange={handleUserChange}
                    className="mr-2"
                  />
                  {user.name}
                </label>
              ))}
            </div>
          </div>
          <div className="flex justify-end gap-2">
            <button
              type="button"
              onClick={() => setModalOpen(false)}
              className="px-3 py-1.5 text-sm text-gray-600 hover:text-gray-800"
            >
              Close
            </button>
            <button
              type="submit"
              className="flex items-center gap-1.5 rounded bg-blue-500 px-3 py-1.5 text-sm text-white hover:bg-blue-600"
            >
              <span>Add</span>
              <FiPlus />
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddCard;
