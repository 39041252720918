import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./CustomPasswordInput.module.css"; // Import CSS Module for component-specific styles
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons from react-icons

const CustomPasswordInput = ({
  label,
  placeholder,
  value,
  onChange,
  width,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const inputType = showPassword ? "text" : "password";

  const inputStyle = {
    width: width ? width : "100%", // Default to 100% width if not provided
  };

  return (
    <div className={`form-group ${styles.customPasswordInput}`}>
      <label className="text-left mb-2">{label}</label>
      <div className={styles.inputContainer}>
        <input
          type={inputType}
          className={`form-control ${styles.input}`}
          style={inputStyle}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
        <button
          type="button"
          className={`toggleButton ${styles.toggleButton}`}
          onClick={togglePasswordVisibility}
        >
          {showPassword ? (
            <FaEyeSlash className={`toggleIcon ${styles.toggleIcon}`} />
          ) : (
            <FaEye className={`toggleIcon ${styles.toggleIcon}`} />
          )}
        </button>
      </div>
    </div>
  );
};

CustomPasswordInput.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string, // Optional custom width as a string (e.g., '300px', '50%', etc.)
};

export default CustomPasswordInput;
