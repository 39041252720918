import React, { useState } from "react";
import styles from "./CalendarDays.css";

function CalendarDays(props) {
    const [selectedDay, setSelectedDay] = useState(props.day ? new Date(props.day) : new Date());
    const [events, setEvents] = useState([]);

    const day = props.day ? new Date(props.day) : new Date();
    const currentDate = new Date();

    // Get the first day of the current month
    let firstDayOfMonth = new Date(day.getFullYear(), day.getMonth(), 1);
    let weekdayOfFirstDay = firstDayOfMonth.getDay();

    // Calculate the number of days in the current month
    let daysInMonth = new Date(day.getFullYear(), day.getMonth() + 1, 0).getDate();

    let currentDays = [];

    // Fill the preceding empty cells with dates from the previous month
    for (let i = 0; i < weekdayOfFirstDay; i++) {
        let prevMonthDate = new Date(firstDayOfMonth);
        prevMonthDate.setDate(firstDayOfMonth.getDate() - (weekdayOfFirstDay - i));
        currentDays.push({
            currentMonth: false,
            date: prevMonthDate,
            number: prevMonthDate.getDate(),
            classes: 'day-cell disabled'
        });
    }

    // Fill the days of the current month
    for (let i = 1; i <= daysInMonth; i++) {
        let date = new Date(day.getFullYear(), day.getMonth(), i);
        let isSelected = date.toDateString() === selectedDay.toDateString();
        let isCurrentDay = date.toDateString() === currentDate.toDateString();
        currentDays.push({
            currentMonth: true,
            date: date,
            number: i,
            classes: `day-cell ${isSelected ? 'selected' : ''}`,
            isCurrentDay: isCurrentDay,
            isSelected: isSelected,
            events: events.filter(event => new Date(event.date).toDateString() === date.toDateString())
        });
    }

    // Fill the remaining cells with dates from the next month
    let remainingCells = 42 - currentDays.length;
    for (let i = 1; i <= remainingCells; i++) {
        let nextMonthDate = new Date(day.getFullYear(), day.getMonth() + 1, i);
        currentDays.push({
            currentMonth: false,
            date: nextMonthDate,
            number: nextMonthDate.getDate(),
            classes: 'day-cell disabled'
        });
    }

    const handleDayClick = (date) => {
        setSelectedDay(date);
        props.changeCurrentDay(date);
    };

    return (
        <div className="table-content">
            {currentDays.map((day, index) => (
                <div key={index} className={`${day.classes}`} onClick={() => handleDayClick(day.date)}>
                    <p className={`day-number ${day.isCurrentDay || day.isSelected ? 'current-day' : ''}`}>{day.number}</p>
                    {day.events && day.events.map((event, eventIndex) => (
                        <div key={eventIndex} className="event" style={{ backgroundColor: event.color }}>
                            {event.title}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default CalendarDays;
