import React from "react";
import DashboardLayout from "../../../layouts/dashboard/DashboardLayout";
import styles from "./TasksDetails.module.css";
import CustomTextarea from "../../../components/inputs/textareaInput/CustomTextarea";
import CustomTextInput from "../../../components/inputs/textInput/CustomTextInput";

import { LuCalendarClock, LuDownload } from "react-icons/lu";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { GrResources } from "react-icons/gr";
import { IoIosAttach } from "react-icons/io";
import { FaCodeBranch, FaRegTrashAlt } from "react-icons/fa";

const TasksDetails = () => {
  return (
    <DashboardLayout className={styles.wrapper}>
      <h3>Create terms of service and privacy policy</h3>
      <p>Project Name</p>
      <div className={styles.details}>
        <div className={styles.forms}>
          <CustomTextarea
            label="Definition of Ready"
            value=""
            onChange={() => console.log("hello")}
            placeholder="Description"
          />
          <CustomTextarea
            label="Definition of Done"
            value=""
            onChange={() => console.log("hello")}
            placeholder="Acceptance Criteria"
          />
          <div className={styles.comments}>
            <p>Comments</p>
            <div className={styles.commemtInput}>
              <img src="/images/Frame1.png" />
              <div className={styles.input}>
                <CustomTextInput
                  value=""
                  onChange={() => console.log("input")}
                  placeholder="Comment"
                  // width="180%"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightSection}>
          <div className={styles.settings}>
            <div className={styles.item}>
              <div className={styles.title}>Assignee</div>
              <div className={styles.value}>
                <img src="/images/Frame1.png" /> <span>John Doe</span>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.title}>Label</div>
              <div className={styles.value}>
                <GrResources size={20} />
                <select>
                  <option>Select option</option>
                  <option>Front-End</option>
                  <option>Back-End</option>
                  <option>Designing</option>
                </select>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.title}>Priority</div>
              <div className={styles.value}>
                <HiOutlineMenuAlt4 size={20} color="#dbd221" />
                <span>Medium</span>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.title}>Start Date</div>
              <div className={styles.value}>
                <LuCalendarClock size={20} /> <span>12/05/2014</span>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.title}>End Date</div>
              <div className={styles.value}>
                <LuCalendarClock size={20} />
                <span>12/05/2014</span>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.title}>Status</div>
              <div className={styles.value}>
                <select>
                  <option>Select option</option>
                  <option>Todo</option>
                  <option>In Progress</option>
                  <option>Under Review</option>
                  <option>Done</option>
                </select>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.title}>Story Point</div>
              <div className={styles.value}>3</div>
            </div>
            <div className={styles.item}>
              <div className={styles.title}>
                <button>
                  <IoIosAttach size={20} /> Attach a file
                </button>
              </div>
              <div className={styles.value}>
                <button>
                  <FaCodeBranch size={20} /> Add subtask
                </button>
              </div>
            </div>
          </div>

          <div className={styles.subtasks}>
            <h4>Subtasks</h4>
            {[1, 1, 1, 1, 1, 1].map((item, index) => {
              return (
                <div className={styles.item} key={index}>
                  <div className={styles.title}>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </div>
                  <div className={styles.value}>
                    <HiOutlineMenuAlt4 size={20} color="#dbd221" />
                    <img src="/images/Frame1.png" />
                    <select>
                      <option>Select option</option>
                      <option>Todo</option>
                      <option>In Progress</option>
                      <option>Under Review</option>
                      <option>Done</option>
                    </select>
                  </div>
                </div>
              );
            })}
          </div>

          <div className={styles.attachments}>
            <h4>Attachments</h4>

            <div className={styles.attachmentItems}>
              {[1, 1, 1, 1, 1].map((item, index) => {
                return (
                  <div className={styles.card} key={index}>
                    <div className={styles.cardTop}>
                      <button>
                        <LuDownload />
                      </button>{" "}
                      <button>
                        <FaRegTrashAlt />
                      </button>
                    </div>
                    <div className={styles.imgDiv}>
                      <img src="/images/Frame1.png" />
                    </div>
                    <div className={styles.cardBottom}>attachment.png</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default TasksDetails;
