import React, { useState, useEffect } from "react";
import "./LoadingScreen.css";

const LoadingScreen = () => {
  const [loading, setLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 5;

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(true);
    }, 10000); // Simulating a 10-second load time

    const stepTimer = setInterval(() => {
      setCurrentStep((prevStep) => (prevStep % totalSteps) + 1);
    }, 1000); // Change step every second

    return () => {
      clearTimeout(timer);
      clearInterval(stepTimer);
    }
  }, []);

  return (
    <div className="loading-screen-container">
      {loading ? (
        <>
          <div className="loading-screen">
            <img src="/images/loadingicon.png" alt="Loading" className="loading-image" />
            <div className='text-container'>

              <h1 className="loading-header">

                Scale your organization and <br />effectively manage all activities
              </h1>
              <h3 className='text'>Project Nova will help your organization run like a well<br /> oiled machine with all our available features.</h3>

            </div>
            <div className="pagination-bullets">
              {Array.from({ length: totalSteps }).map((_, index) => (
                <span
                  key={index}
                  className={`bullet ${index + 1 === currentStep ? 'active' : ''}`}
                />
              ))}
            </div>
          </div>

        </>
      ) : (
        <div className="content">
          <h1>Content Loaded</h1>
          {/* Your content goes here */}
        </div>
      )}
    </div>
  );
};

export default LoadingScreen;
