import React from "react";
import "./Login.css";
import CustomTextInput from "../../../components/inputs/textInput/CustomTextInput";
import CustomPasswordInput from "../../../components/inputs/passwordInput/CustomPasswordInput";
import CustomButton from "../../../components/inputs/button/CustomButton";
import AuthLayout from "../../../layouts/auth/AuthLayout";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/dashboard");
  };
  return (
    <div className="login-page">
      <AuthLayout
        imageComponent={
          <div className="column left">
            <img src="/images/frame1.png" alt="Image" />
          </div>
        }
        contentComponent={
          <div className="column right">
            <form className="login-form">
              <h1>
                Work<span>Nova</span>
              </h1>
              <p>Effectively manage your team with ease</p>
              <div className="emailField">
                <CustomTextInput
                  label={"Email"}
                  placeholder={"Enter your email"}
                />
              </div>
              <div className="passwordField">
                <CustomPasswordInput
                  label={"Password"}
                  placeholder={"Enter your password"}
                />
              </div>
              <div className="forgotPass">
                <label>
                  <input type="checkbox" />
                  Remember Me
                </label>
                <a href="#">Forgot Password?</a>
              </div>
              <div className="login-button">
                <CustomButton
                  size={"small"}
                  fullWidth={"true"}
                  onClick={handleLogin}
                >
                  Log In
                </CustomButton>
                <div className="break"></div>
                <CustomButton variant={"outlined"} size={"small"}>
                  Continue with Google
                </CustomButton>
              </div>

              <div className="signup">
                <p>
                  Don't have an account? <Link to={"/signup"}>SignUp</Link>
                </p>
              </div>
            </form>
          </div>
        }
      />
    </div>
  );
};

export default Login;
