import React, { useState } from "react";
import DashboardLayout from "../../../layouts/dashboard/DashboardLayout";
import CustomTextarea from "../../../components/inputs/textareaInput/CustomTextarea";
import CustomTextInput from "../../../components/inputs/textInput/CustomTextInput";
import CustomPasswordInput from "../../../components/inputs/passwordInput/CustomPasswordInput";
import CustomButton from "../../../components/inputs/button/CustomButton"; 
import { FaEdit } from "react-icons/fa";
import styles from "./UserProfile.module.css";

const UserProfile = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const handleNewPasswordChange = (e) => setNewPassword(e.target.value);
  const handleConfirmNewPasswordChange = (e) => setConfirmNewPassword(e.target.value);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");

  const handleFullNameChange = (e) => setFullName(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);

  return (
    <DashboardLayout className={styles.wrapper}>
      <h3>Workspace/User Profile</h3>
      <div className={styles.details}>
        <div className={styles.generalInfo}>
          <h4>General Information</h4>
          <div className={styles.profileImage}>
            <img src="/images/Frame4.png"  alt="Logo Icon" />
            <div className={styles.editIcon}>
              <FaEdit size={12} color="#fff" />
            </div>
          </div>
          <div className={styles.input}>
            <CustomTextInput
              label="Full Name"
              placeholder="Enter your full name"
              value={fullName}
              onChange={handleFullNameChange}
              width="100%"
            />
          </div>
          <div className={styles.input}>
            <CustomTextarea
              label="Bio"
              value=""
              onChange={() => console.log("hello")}
              placeholder="Write something to describe you"
              width="100%"
            />
          </div>
          <CustomButton type="submit" variant="primary" size="small" customWidth="100%">
             Save Changes
            </CustomButton>
        </div>
        <div className={styles.rightSection}>
          <div className={styles.personalInfo}>
            <h4>Personal Information</h4>
            <div className={styles.input}>
              <CustomTextInput
                label="Email"
                placeholder="Enter your email"
                value={email}
                onChange={handleEmailChange}
                width="100%"
                className={styles.formInput}
              />
            </div>
            <div className={styles.input}>
              <CustomTextInput
                label="Address"
                value=""
                onChange={() => console.log("input")}
                placeholder="Enter your address"
                width="100%"
              />
            </div>
            <div className={styles.input}>
              <CustomTextInput
                label="Phone Number"
                value=""
                onChange={() => console.log("input")}
                placeholder="Enter your phone number"
                width="100%"
              />
            </div>
            <CustomButton type="submit" variant="primary" size="small" customWidth="100%">
            update information
            </CustomButton>
          </div>
          <div className={styles.changePassword}>
            <h4>Change Password</h4>
            <div className={styles.inputField}>
              <CustomPasswordInput
                label="Old Password"
                placeholder="Enter your old password"
                value=""
                onChange={() => console.log("input")}
                className={styles.inputField}
                width="100%"
              />
            </div>
            <div className={styles.inputField}>
              <CustomPasswordInput
                label="New Password"
                placeholder="Enter your new password"
                value={newPassword}
                onChange={handleNewPasswordChange}
                className={styles.inputField}
                width="100%"
              />
            </div>
            <div className={styles.inputField}>
              <CustomPasswordInput
                label="Confirm New Password"
                placeholder="Retype your new password"
                value={confirmNewPassword}
                onChange={handleConfirmNewPasswordChange}
                className={styles.inputField}
                width="100%"
              />
            </div>
            <CustomButton type="submit" variant="primary" size="small" customWidth="100%">
              Reset Password
            </CustomButton>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default UserProfile;
