import React, { useState } from "react";
import DashboardLayout from "../../../layouts/dashboard/DashboardLayout";
import CustomTextarea from "../../../components/inputs/textareaInput/CustomTextarea";
import CustomTextInput from "../../../components/inputs/textInput/CustomTextInput";
import CustomButton from "../../../components/inputs/button/CustomButton";
import { FaPlus, FaEdit } from "react-icons/fa";
import { MdPersonAdd } from 'react-icons/md';
import styles from "./Settings.module.css";

const Settings = () => {
  const [fullName, setFullName] = useState("");
  const [tagline, setTagline] = useState("");
  const [description, setDescription] = useState("");

  const handleFullNameChange = (e) => setFullName(e.target.value);
  const handleTaglineChange = (e) => setTagline(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);

  return (
    <DashboardLayout className={styles.wrapper}>
      <h3>Workspace / User Profile</h3>
      <div className={styles.details}>
        <div className={styles.forms}>
          <h3>General Information</h3>
          <CustomTextInput
            label="Company Name"
            placeholder="Enter your company name"
            value={fullName}
            onChange={handleFullNameChange}
            width="100%"
          />
          <CustomTextInput
            label="Company Tagline"
            placeholder="Enter your company tagline"
            value={tagline}
            onChange={handleTaglineChange}
            width="100%"
          />
          <CustomTextarea
            label="Company Description"
            placeholder="Write something to describe your company"
            value={description}
            onChange={handleDescriptionChange}
            width="100%"
          />
          <CustomButton
            type="button"
            variant="primary"
            size="small"
            customWidth="100%"
            onClick={() => console.log("Save changes clicked")}
          >
            Save changes
          </CustomButton>

          <div className={styles.roles}>
            <h4>Project Roles</h4>
            <div className={styles.settings}>
              <div className={styles.item}>
                <div className={styles.title}>Project Manager</div>
                <div className={styles.value}>
                  <img src="/images/Frame1.png" />
                  <div>John Doe</div>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.title}>Scrum Master</div>
                <div className={styles.value}>
                  <img src="/images/Frame1.png" />
                  <div>John Doe</div>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.title}>Front-End Lead </div>
                <div className={styles.value}>
                  <img src="/images/Frame1.png" />
                  <div>John Doe</div>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.title}>Project Role </div>
                <div className={styles.value}>
                <button className={styles.addButton}>
                <MdPersonAdd size={20} />
                </button>
                </div>
              </div>

              <div className={styles.item}>
                <div className={styles.title}>
                  <button>
                    <FaPlus size={15} /> Add Role
                  </button>
                </div>
              </div>
            </div>
          </div> <br />

          <CustomButton
            type="button"
            variant="primary"
            size="small"
            customWidth="100%"
            onClick={() => console.log("Save changes clicked")} >
           Save changes
          </CustomButton>
        </div>

        <div className={styles.rightSection}>
          <h3>Company Branding</h3>
          <div className={styles.profileImage}>
          <img src="/images/Frame4.png" alt="full logo"  />
            <div className={styles.editIcon}>
              <FaEdit size={12} color="#fff" />
            </div>
          </div>
          <div className={styles.profileImage}>
            <img src="/images/Frame4.png"  alt="Logo Icon" />
            <div className={styles.editIcon}>
              <FaEdit size={12} color="#fff" />
            </div>
          </div>
          <div className={styles.item}>
              <div className={styles.title}>Theme</div>
              <div className={styles.value}>
                <select>
                  <option>Light</option>
                  <option>Dark</option>
                </select>
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.title}>Accent color 1</div>
              <div className={styles.value}>
                <select>
                  <option>Select color</option>
                  <option>...</option>
                </select>
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.title}>Accent color 2</div>
              <div className={styles.value}>
                <select>
                  <option>Select color</option>
                  <option>...</option>
                </select>
              </div>
            </div>
            
            <div className={styles.item}>
              <div className={styles.title}>Heading font</div>
              <div className={styles.value}>
                <select>
                  <option>Select font</option>
                  <option>...</option>
                </select>
              </div>
            </div>
            
            <div className={styles.item}>
              <div className={styles.title}>Body font</div>
              <div className={styles.value}>
                <select>
                  <option>Select font</option>
                  <option>...</option>
                </select>
              </div>
            </div>
        
          <CustomButton
            type="submit"
            variant="primary"
            size="small"
            customWidth="100%"
            onClick={() => console.log("Update Brand Elements clicked")}
          >
            Update Brand Elements
          </CustomButton>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Settings;
