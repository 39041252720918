import React from "react";
import PropTypes from "prop-types";
import styles from "./CustomTextarea.module.css"; // Import CSS Module for component-specific styles

const CustomTextarea = ({ label, value, onChange, placeholder, width }) => {
  const inputStyle = {
    width: width ? width : "100%", // Default to 100% width if not provided
  };
  return (
    <div className={styles.customTextareaContainer}>
      {label && <label className={styles.customTextareaLabel}>{label}</label>}
      <textarea
        value={value}
        onChange={onChange}
        className={styles.customTextarea}
        placeholder={placeholder}
        style={inputStyle}
      />
    </div>
  );
};

CustomTextarea.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  width: PropTypes.string,
};

CustomTextarea.defaultProps = {
  label: "",
  placeholder: "",
};

export default CustomTextarea;
