import React from "react";
import styles from "./styles.module.css";
import DashboardLayout from "../../../../layouts/dashboard/DashboardLayout";
import { FaLongArrowAltRight } from "react-icons/fa";
import { TbPlayerEjectFilled } from "react-icons/tb";
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import { IoIosTime } from "react-icons/io";
import DatePickerIcon from "../../../../components/inputs/DatePicker/DatePicker";
import Header from "../TimeTrackingHeader/Header";

const MeetingReports = () => {
  return (
    <DashboardLayout>
      <div className={styles.wrapper}>
        <Header />

        <div className={styles.timeTracker}>
          <h3>Meeting Report</h3>
          <div className={styles.dataPagination}>
            <div>1 July - 7 July</div>
            <div className={styles.icon}>
              <DatePickerIcon />
            </div>
          </div>

          <div className={styles.contentWrapper}>
            {[1, 1, 1, 1, 1, 1].map((item, index) => {
              return (
                <div className={styles.dateTracker}>
                  <div className={styles.dateSection}>
                    <div>
                      <div className={styles.day}>Monday July 1</div>
                      <div className={styles.hours}>10h 23m 51s</div>
                      <div className={styles.action}>
                        Edit <FaLongArrowAltRight />
                      </div>
                    </div>
                  </div>
                  <div className={styles.timeSection}>
                    {[1, 1, 1, 1].map((item, index) => {
                      return (
                        <div className={styles.item} key={index}>
                          <div className={styles.content}>
                            Lorem ipsum dolor sit, amet consectetur adipisicing
                            elit. Ipsum excepturi, dolorum inventore repellat.
                          </div>
                          <div className={styles.timeStamps}>
                            <div className={styles.data}>
                              <div className={styles.icon}>
                                <IoIosTime />
                              </div>
                              <div className={styles.time}>06:00 PM</div>
                            </div>
                            <div className={styles.data}>
                              <div className={styles.icon}>
                                <TbPlayerEjectFilled />
                              </div>
                              <div className={styles.time}>06:31 PM</div>
                            </div>
                            <div className={styles.data}>
                              <div className={styles.icon}>
                                <MdOutlineAccessTimeFilled />
                              </div>
                              <div className={styles.time}>31m 25s</div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default MeetingReports;
