import React, { useState } from "react";
import { MdSearch, MdEdit } from "react-icons/md";

import CustomButton from "../components/inputs/button/CustomButton";
import CustomPasswordInput from "../components/inputs/passwordInput/CustomPasswordInput";
import CustomTextInput from "../components/inputs/textInput/CustomTextInput";
import CustomTextInputWithIcon from "../components/inputs/textInputWithIcon/CustomInputWithIcon";
import CustomCard from "../components/cards/todo/CustomCard";
import CustomTextarea from "../components/inputs/textareaInput/CustomTextarea";
import Sidebar from "../components/dashboard/sidebar/Sidebar";

const Components = () => {
  const [password, setPassword] = useState("");

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  const handleClick = () => {
    alert("Button clicked!");
  };

  const labels = [
    "Label 1",
    "Label 2",
    "Label 3",
    "Label 1",
    "Label 2",
    "Label 3",
  ];
  const imageUrl = "";

  const [text, setText] = useState("");

  const handleTextChange = (event) => {
    setText(event.target.value);
  };
  return (
    <div className="container">
      <Sidebar />

      <CustomCard
        title="Card Title"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        labels={labels}
        message="Sent a message"
        imageUrl={imageUrl}
        count1={2}
        count2={2}
      />
      <CustomTextInput
        label="Enter Name"
        value={text}
        onChange={handleTextChange}
        placeholder="Enter your name..."
      />

      <CustomTextInputWithIcon
        label="Search"
        value={text}
        onChange={handleTextChange}
        placeholder="Type to search..."
        icon={MdSearch} // Use custom icon
      />

      <CustomPasswordInput
        label="Password"
        placeholder="Enter your password"
        value={password}
        onChange={handleChange}
      />

      <CustomTextarea
        label="Description"
        value={text}
        onChange={handleTextChange}
        placeholder="Type your description here..."
      />
      <CustomButton onClick={handleClick}>Default Button</CustomButton>
      <CustomButton onClick={handleClick} variant="primary">
        Primary Button
      </CustomButton>
      <CustomButton onClick={handleClick} variant="secondary">
        Secondary Button
      </CustomButton>
      <CustomButton onClick={handleClick} variant="outlined">
        Outlined Button
      </CustomButton>

      <CustomButton onClick={handleClick} size="large">
        Large Button
      </CustomButton>
      <CustomButton onClick={handleClick} size="small">
        Small Button
      </CustomButton>

      <CustomButton onClick={handleClick} disabled>
        Disabled Button
      </CustomButton>

      <CustomButton onClick={handleClick} fullWidth>
        Full Width Button
      </CustomButton>
      <CustomButton onClick={handleClick} customWidth="50%">
        50% Width Button
      </CustomButton>
    </div>
  );
};

export default Components;
