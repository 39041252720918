import React, { useState } from "react";
import { FiPlus } from "react-icons/fi";
import { motion } from "framer-motion";

const AddColumn = ({ setColumns }) => {
    const [title, setTitle] = useState("");
    const [adding, setAdding] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!title.trim().length) return;

        const newColumn = {
            id: title.toLowerCase().replace(/\s+/g, ""),
            title: title.trim(),
            headingColor: "text-neutral-500",
        };

        setColumns((pv) => [...pv, newColumn]);

        setAdding(false);
    };

    return (
        <>
            {adding ? (
                <motion.form layout onSubmit={handleSubmit} className="w-56 shrink-0" >
                    <input
                        onChange={(e) => setTitle(e.target.value)}
                        autoFocus
                        placeholder="New column title"
                        className="w-full rounded border border-violet-300 bg-white p-3 text-sm text-gray-900 placeholder-gray-400 focus:outline-0"
                    />
                    <div className="mt-1.5 flex items-center justify-end gap-1.5">
                        <button
                            onClick={() => setAdding(false)}
                            className="px-3 py-1.5 text-xs text-gray-600 transition-colors hover:text-gray-900"
                        >
                            Close
                        </button>
                        <button
                            type="submit"
                            className="flex items-center gap-1.5 rounded bg-gray-900 px-3 py-1.5 text-xs text-white transition-colors hover:bg-gray-700"
                        >
                            <span>Add</span>
                            <FiPlus />
                        </button>
                    </div>
                </motion.form>
            ) : (
                <motion.button
                    layout
                    onClick={() => setAdding(true)}
                    className="flex w-56 items-center gap-1.5 px-3 py-1.5 text-xs text-gray-600 transition-colors hover:text-gray-900"
                >
                    <span>Add column</span>
                    <FiPlus />
                </motion.button>
            )}
        </>
    );
};

export default AddColumn;
