import React from "react";
import PropTypes from "prop-types";
import styles from "./CustomCard.module.css"; // Import CSS Module for component-specific styles
import { MdMessage, MdAttachFile } from "react-icons/md"; // Import message and attachment icons
import { Link } from "react-router-dom";

const CustomCard = ({
  title,
  description,
  labels,
  message,
  count1,
  count2,
  imageUrl,
}) => {
  return (
    <Link to="project-details" className={styles.wrapper}>
      <div className={styles.customCard}>
        <div>
          <div className={styles.customCardTitle}>{title}</div>
          <div className={styles.customCardDescription}>{description}</div>
          <div className={styles.customCardLabels}>
            {labels.map((label, index) => (
              <div key={index} className={styles.customCardLabel}>
                {label}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.customCardFooter}>
          <div className={styles.customCardFooterLeft}>
            <img
              src={imageUrl}
              alt="Profile"
              className={styles.customCardFooterImage}
            />
            {/* <div className={styles.customCardFooterMessage}>{message}</div> */}
          </div>
          <div className={styles.customCardFooterRight}>
            <MdMessage className={styles.customCardFooterIcon} />
            {count1}
            <MdAttachFile className={styles.customCardFooterIcon} />
            {count2}
          </div>
        </div>
      </div>
    </Link>
  );
};

CustomCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  message: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

export default CustomCard;
