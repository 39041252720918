import './App.css';
import Onboarding from './pages/onboarding/Onboarding';
import Login from './pages/auth/login/Login';
import Signup from './pages/auth/signup/Signup';

import Dashboard from './pages/dasboard/dashboard/Dashboard';
import Projects from "./pages/dasboard/projects/Projects"
import ProjectDetails from './pages/dasboard/projects/projectDetails/ProjectDetails';
import TasksDetails from './pages/dasboard/tasks/TasksDetails.jsx';

import Reports from './pages/dasboard/reports/Reports.jsx';
import MeetingReport from './pages/dasboard/reports/MeetingReport/MeetingReport.jsx';

import TimeTracking from './pages/dasboard/TimeTracking/TimeTracking';
import TimeTrackingMeetingReports from './pages/dasboard/TimeTracking/MeetingReports/MeetingReports.jsx';

import Categories from './pages/dasboard/categories/Categories.jsx';
import CategoryDetails from './pages/dasboard/categories/categoryDetails/CategoryDetails.jsx';
import ProjectsPage from './pages/dasboard/categories/projects/Projects.jsx';

import ForgotPassword from './pages/auth/forgotpswd/ForgotPassword.jsx';
import RecoverPassword from './pages/auth/recoverypswd/RecoverPassword';
import ErrorPage from './pages/errorPage/ErrorPage.jsx';

import Components from './pages/Components';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import LoadingScreen from './pages/LoadingScreen/LoadingScreen';
import UpcomingEvents from './pages/dasboard/myschedule/UpcomingEvents/UpcomingEvents.jsx';
import MyActivities from './pages/dasboard/myschedule/myActivities/MyActivities.jsx';
import Modal from 'react-modal';
import EventName from './pages/dasboard/projects/EventName/EventName.jsx';
import UserProfile from './pages/dasboard/userprofile/UserProfile.jsx';
import Settings from './pages/dasboard/userprofile/Settings.jsx'


Modal.setAppElement('#root');

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* Loading Screen Route */}
          <Route path="/loading" element={<LoadingScreen />} />

          {/* UNPROTECTED ROUTES */}
          <Route path="/" element={<Onboarding />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/recover-password" element={<RecoverPassword />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/components" element={<Components />} />

          <Route path="/upcoming-events" element={<UpcomingEvents />} />
          <Route path="/my-activities" element={<MyActivities />} />

          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/burn-down-chart" element={<Reports />} />
          <Route path="/meeting-report" element={<MeetingReport />} />

          <Route path="/time-tracking" element={<TimeTracking />} />
          <Route path="/time-tracking/meeting-report" element={<TimeTrackingMeetingReports />} />


          <Route path="/project-1" element={<Projects />} />
          <Route path="/project-1/project-details" element={<ProjectDetails />} />
          <Route path="/myschedule/event-name" element={<EventName />} />
          <Route path="/tasks/edit" element={<TasksDetails />} />

          <Route path="/categories" element={<Categories />} />
          <Route path="/categories/edit" element={<CategoryDetails />} />
          <Route path="/categories/projects" element={<ProjectsPage />} />

          <Route path="/user-profile" element={<UserProfile />} />
          <Route path='/settings' element={<Settings />} />

          {/* 404 ROUTE */}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
      {/* USING THE TOASTIFY CONTAINER */}
      <Toaster
        position="top-center"
        reverseOrder={false}
      />

    </div>
  );
}

export default App;
