import React, { useState } from "react";
import DashboardLayout from "../../../../layouts/dashboard/DashboardLayout";
import ReportHeader from "../ReportHeader/ReportHeader";
import styles from "./styles.module.css";
import LineChart from "../../../../components/charts/Line/LineChart";
import BarChart from "../../../../components/charts/Bar/BarChart";

import { Link } from "react-router-dom";
import {
  FaPlay,
  FaLongArrowAltRight,
  FaChevronRight,
  FaClock,
} from "react-icons/fa";
import { HiOutlineMenuAlt4 } from "react-icons/hi";

const projectsData = [
  {
    name: "Mike Dane",
    profilePhoto: "/images/Frame1.png",
    email: "sird@example.com",
  },
  {
    name: "Mike Dane",
    profilePhoto: "/images/Frame1.png",
    email: "sird@example.com",
  },
  {
    name: "Mike Dane",
    profilePhoto: "/images/Frame1.png",
    email: "sird@example.com",
  },
  {
    name: "Mike Dane",
    profilePhoto: "/images/Frame1.png",
    email: "sird@example.com",
  },
  {
    name: "Mike Dane",
    profilePhoto: "/images/Frame1.png",
    email: "sird@example.com",
  },
  {
    name: "Mike Dane",
    profilePhoto: "/images/Frame1.png",
    email: "sird@example.com",
  },
];

const MeetingReport = () => {
  const [projects, setProjects] = useState(projectsData);

  return (
    <DashboardLayout>
      <div className={styles.wrapper}>
        <ReportHeader />

        <div className={styles.lineChart}>
          <h3>Average Meeting Report</h3>
          <BarChart />
        </div>

        <div className={styles.listProjects}>
          <h3>Projects</h3>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Profile Photo</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {projects.map((record, index) => (
                <tr key={index}>
                  <td>{record.name}</td>
                  <td>
                    <img src={record.profilePhoto} />
                  </td>
                  <td>{record.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={styles.listAll}>
            <Link to="#">
              <span>View all</span> <FaLongArrowAltRight />
            </Link>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default MeetingReport;
