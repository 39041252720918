import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styles from "./Sidebar.module.css";
import {
  MdDashboard,
  MdExpandMore,
  MdExpandLess,
  MdOutlineBarChart,
} from "react-icons/md";
import { FaArrowLeft, FaArrowRight, FaChartLine } from "react-icons/fa";
import { BiSolidCarousel } from "react-icons/bi";
import { RiCustomerService2Line } from "react-icons/ri";
import { RiStackFill } from "react-icons/ri";
import { CgSandClock } from "react-icons/cg";
import { AuthContext } from "../../../contexts/AuthContext";

const SidebarItem = ({
  item,
  isExpanded,
  toggleDropdown,
  isSelected,
  onSelect,
}) => {
  return (
    <div
      className={`${styles.sidebarItemWrapper} `}
      onClick={(item) => onSelect(item.id)}
    >
      {item.subItems.length > 0 ? (
        <div>
          <div
            className={`${styles.sidebarItem} ${
              isSelected ? styles.selected : ""
            }`}
            onClick={() => toggleDropdown(item.id)}
          >
            <div className={styles.sidebarIcon}>{item.icon}</div>
            <div
              className={`${styles.sidebarText} ${
                isExpanded ? "" : styles.hidden
              }`}
            >
              {item.title}
            </div>
            <div className={styles.sidebarIcon}>
              {item.isOpen ? <MdExpandLess /> : <MdExpandMore />}
            </div>
          </div>
          <div
            className={`${styles.sidebarDropdown} ${
              item.isOpen ? styles.show : ""
            }`}
          >
            {item.subItems.map((subItem) => (
              <div className={styles.subItemContainer} key={subItem.id}>
                <SidebarItem
                  item={subItem}
                  isExpanded={isExpanded}
                  toggleDropdown={toggleDropdown}
                  isSelected={isSelected === subItem.id}
                  onSelect={onSelect}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Link
          onClick={(item) => onSelect(item.id)}
          to={item.link}
          className={`${styles.sidebarItem} ${
            isSelected ? styles.selected : ""
          }`}
        >
          <div className={styles.sidebarIcon}>{item.icon}</div>
          <div
            className={`${styles.sidebarText} ${
              isExpanded ? "" : styles.hidden
            }`}
          >
            {item.title}
          </div>
        </Link>
      )}
    </div>
  );
};

SidebarItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    subItems: PropTypes.array.isRequired,
    isOpen: PropTypes.bool.isRequired,
  }).isRequired,
  isExpanded: PropTypes.bool.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};

const Sidebar = () => {
  const { role } = useContext(AuthContext);

  const [isExpanded, setIsExpanded] = useState(true);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [sidebarItems, setSidebarItems] = useState([
    {
      id: 1,
      title: "Dashboard",
      icon: <MdDashboard />,
      subItems: [],
      isOpen: false,
      link: "/dashboard",
    },
    {
      id: 13,
      title: "Reports",
      icon: <FaChartLine />,
      subItems: [
        {
          id: 14,
          title: "Burn-down Chart",
          icon: <FaChartLine />,
          subItems: [],
          isOpen: false,
          link: "/burn-down-chart",
        },
        role === "ADMIN" && {
          id: 15,
          title: "Meeting Report",
          icon: <CgSandClock />,
          subItems: [],
          isOpen: false,
          link: "/meeting-report",
        },
      ].filter(Boolean),
      isOpen: false,
      link: "#",
    },
    {
      id: 16,
      title: "Time Tracker",
      icon: <CgSandClock />,
      subItems: [
        {
          id: 17,
          title: "Time Tracker",
          icon: <FaChartLine />,
          subItems: [],
          isOpen: false,
          link: "/time-tracking",
        },
        {
          id: 18,
          title: "Meeting Report",
          icon: <FaChartLine />,
          subItems: [],
          isOpen: false,
          link: "/time-tracking/meeting-report",
        },
      ],
      isOpen: false,
      link: "#",
    },
    {
      id: 12,
      title: "Categories",
      icon: <RiCustomerService2Line />,
      subItems: [],
      isOpen: false,
      link: "/categories",
    },
    {
      id: 2,
      title: "Projects",
      icon: <MdOutlineBarChart />,
      link: "#",
      subItems: [
        {
          id: 3,
          title: "Pinned Projects",
          icon: <MdOutlineBarChart />,
          subItems: [],
          isOpen: false,
          link: "/pinned-projects",
        },
        {
          id: 4,
          title: "Category 1",
          icon: <MdOutlineBarChart />,
          link: "",
          subItems: [
            {
              id: 5,
              title: "Project 1",
              icon: <MdOutlineBarChart />,
              subItems: [],
              isOpen: false,
              link: "/project-1",
            },
            {
              id: 6,
              title: "Project 2",
              icon: <MdOutlineBarChart />,
              subItems: [],
              isOpen: false,
              link: "/project-2",
            },
            {
              id: 7,
              title: "Project 3",
              icon: <MdOutlineBarChart />,
              subItems: [],
              isOpen: false,
              link: "/project-3",
            },
          ],
          isOpen: false,
        },
      ],
      isOpen: false,
    },
    {
      id: 8,
      title: "My Schedule",
      icon: <BiSolidCarousel />,
      link: "#",
      subItems: [
        {
          id: 9,
          title: "Upcoming Events ",
          icon: <BiSolidCarousel />,
          subItems: [],
          isOpen: false,
          link: "/upcoming-events",
        },
        {
          id: 10,
          title: "My Activities ",
          icon: <BiSolidCarousel />,
          subItems: [],
          isOpen: false,
          link: "/my-activities",
        },
      ],
      isOpen: false,
    },
    {
      id: 11,
      title: "Resources",
      icon: <RiStackFill />,
      link: "#",
      subItems: [
        {
          id: 12,
          title: "Resource 1",
          icon: <RiStackFill />,
          subItems: [],
          isOpen: false,
          link: "/ressource-1",
        },
      ],
      isOpen: false,
    },
  ]);

  const handleSelect = (id) => {
    setSelectedItemId(id);
  };

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleDropdown = (id) => {
    const toggleItems = (items) => {
      return items.map((item) => {
        if (item.id === id) {
          item.isOpen = !item.isOpen;
        }
        if (item.subItems.length > 0) {
          item.subItems = toggleItems(item.subItems);
        }
        return item;
      });
    };

    setSidebarItems(toggleItems(sidebarItems));
  };

  return (
    <div className={`${styles.sidebar} ${isExpanded ? "" : styles.collapsed}`}>
      <img src="/images/logo/WorkNOVA.png" alt="Logo" className={styles.logo} />

      {sidebarItems.map((item) => (
        <SidebarItem
          key={item.id}
          item={item}
          isExpanded={isExpanded}
          toggleDropdown={toggleDropdown}
          isSelected={selectedItemId === item.id}
          onSelect={() => handleSelect(item.id)}
        />
      ))}
      <button className={styles.toggleButton} onClick={toggleSidebar}>
        {isExpanded ? <FaArrowLeft /> : <FaArrowRight />}{" "}
        {isExpanded && <span>Collapse Side Menu</span>}
      </button>
    </div>
  );
};

export default Sidebar;
