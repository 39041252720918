import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import styles from "./DashboardLayout.module.css";

import Sidebar from "../../components/dashboard/sidebar/Sidebar";
import TopNav from "../../components/dashboard/topnav/TopNav";

const DashboardLayout = ({ children }) => {
  const { authenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!authenticated) {
  //     navigate("/");
  //     return;
  //   }
  // }, [authenticated, navigate]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.LeftSidebar}>
        <Sidebar />
      </div>
      <div className={styles.mainContent}>
        <div className={styles.topNav}>
          <TopNav />
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default DashboardLayout;
