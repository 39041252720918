import React from "react";
import styles from "./ListItems.module.css";
import { FaPlus } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import ButtonWithIcon from "../btnWithIcon/ButtonWithIcon";
import { BsThreeDots } from "react-icons/bs";
import { Link } from "react-router-dom";

const ListItems = ({
  header,
  titles,
  items,
  labelColor,
  labelBackgroundColor,
}) => {
  return (
    <>
      {titles && (
        <div className={styles.headings}>
          {titles.map((item, index) => {
            return (
              <h5 className={index === 0 ? styles.heading1 : ""} key={index}>
                {item}
              </h5>
            );
          })}
        </div>
      )}

      <div className={styles.section}>
        <div className={styles.sectionHead}>
          {header} <span className={styles.todo}>{items.length}</span>{" "}
          <select>
            <option>Date Added</option>
            <option>12/05/2014</option>
            <option>12/05/2014</option>
            <option>12/05/2014</option>
            <option>12/05/2014</option>
            <option>12/05/2014</option>
          </select>
        </div>

        {items.map((item, index) => {
          return (
            <div className={styles.items} key={index}>
              <div className={styles.item1}>
                <p>{item.taskName}</p>
              </div>
              <div className={styles.item}>
                <img src={item.assignee} about="profile image" />
              </div>
              <div className={styles.item}>
                <span
                  style={{
                    color: labelColor,
                    backgroundColor: labelBackgroundColor,
                  }}
                  className={styles.label}
                >
                  {item.label}
                </span>
              </div>
              <div className={styles.item}>
                <p>{item.dueDate}</p>
              </div>
              <div className={styles.item}>
                <span className={styles.btnFilter}>
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className={styles.toggleBtn}
                    >
                      <ButtonWithIcon
                        onClick={() => console.log("filter")}
                        icon={BsThreeDots}
                        label=""
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={styles.dropdownMenu}>
                      <Dropdown.Item className={styles.dropdownItem}>
                        <Link to="/tasks/edit">Edit</Link>
                      </Dropdown.Item>
                      <Dropdown.Item className={styles.dropdownItem}>
                        <Link to="/tasks/projects">View Projects</Link>
                      </Dropdown.Item>
                      <Dropdown.Item className={styles.dropdownItem}>
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </span>
              </div>
            </div>
          );
        })}

        <div className={styles.addCard}>
          <button>
            <FaPlus /> <span>Add a Card</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default ListItems;
