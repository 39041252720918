import React from "react";
import PropTypes from "prop-types";
import styles from "./CustomTextInput.module.css";

const CustomTextInput = ({ label, placeholder, value, onChange, width }) => {
  const inputStyle = {
    width: width ? width : "100%", // Default to 100% width if not provided
  };

  return (
    <div className="form-group">
      {label && <label className={styles.customInputLabel}>{label}</label>}
      <input
        type="text"
        value={value}
        onChange={onChange}
        className={styles.customInput}
        placeholder={placeholder}
        style={inputStyle}
      />
    </div>
  );
};

CustomTextInput.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string, // Optional custom width as a string (e.g., '300px', '50%', etc.)
};

export default CustomTextInput;
