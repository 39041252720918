import React, { useState } from "react";
import DashboardLayout from "../../../layouts/dashboard/DashboardLayout";
import ReportHeader from "./ReportHeader/ReportHeader";
import styles from "./styles.module.css";
import LineChart from "../../../components/charts/Line/LineChart";
import { Link } from "react-router-dom";
import {
  FaPlay,
  FaLongArrowAltRight,
  FaChevronRight,
  FaClock,
} from "react-icons/fa";
import { HiOutlineMenuAlt4 } from "react-icons/hi";

const projectsData = [
  {
    categoryName: "WorkNova",
    manager: "/images/Frame1.png",
    priority: "medium",
    endDate: "12/02/2015",
    tshirtSize: "XL",
  },
  {
    categoryName: "WorkNova",
    manager: "/images/Frame1.png",
    priority: "medium",
    endDate: "12/02/2015",
    tshirtSize: "XL",
  },
  {
    categoryName: "WorkNova",
    manager: "/images/Frame1.png",
    priority: "medium",
    endDate: "12/02/2015",
    tshirtSize: "XL",
  },
  {
    categoryName: "WorkNova",
    manager: "/images/Frame1.png",
    priority: "medium",
    endDate: "12/02/2015",
    tshirtSize: "XL",
  },
  {
    categoryName: "WorkNova",
    manager: "/images/Frame1.png",
    priority: "medium",
    endDate: "12/02/2015",
    tshirtSize: "XL",
  },
];

const Reports = () => {
  const [projects, setProjects] = useState(projectsData);

  return (
    <DashboardLayout>
      <div className={styles.wrapper}>
        <ReportHeader />

        <div className={styles.lineChart}>
          <h3>My Burn-down Chart</h3>
          <LineChart />
        </div>

        <div className={styles.listProjects}>
          <h3>Projects</h3>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Category Name</th>
                <th>Project Manager</th>
                <th>Priority</th>
                <th>End Date</th>
                <th>T-Shirt Size</th>
              </tr>
            </thead>
            <tbody>
              {projects.map((record, index) => (
                <tr key={index}>
                  <td>{record.categoryName}</td>
                  <td>
                    <img src={record.manager} />
                  </td>
                  <td>
                    <HiOutlineMenuAlt4 color="#FDC532" /> {record.priority}
                  </td>
                  <td>{record.endDate}</td>
                  <td>{record.tshirtSize}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={styles.listAll}>
            <Link to="#">
              <span>View all</span> <FaLongArrowAltRight />
            </Link>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Reports;
