import React from "react";
import PropTypes from "prop-types";
import styles from "./CustomTextInput.module.css"; // Import CSS Module for component-specific styles
import { MdClear } from "react-icons/md"; // Import custom icon

const CustomTextInputWithIcon = ({
  label,
  value,
  onChange,
  placeholder,
  icon: Icon,
  width,
}) => {
  const inputStyle = {
    width: width ? width : "100%", // Default to 100% width if not provided
  };

  return (
    <div className={styles.customInputContainer}>
      {label && <label className={styles.customInputLabel}>{label}</label>}
      <input
        type="text"
        value={value}
        onChange={onChange}
        className={styles.customInput}
        placeholder={placeholder}
        style={inputStyle}
      />
      {Icon && (
        <Icon
          className={styles.customInputIcon}
          onClick={() => onChange({ target: { value: "" } })}
        />
      )}
    </div>
  );
};

CustomTextInputWithIcon.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  icon: PropTypes.elementType, // Icon component from react-icons or custom
};

CustomTextInputWithIcon.defaultProps = {
  label: "",
  placeholder: "",
  icon: MdClear, // Default icon if none is provided
};

export default CustomTextInputWithIcon;
