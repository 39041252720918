import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../layouts/auth/AuthLayout";
import CustomButton from "../../components/inputs/button/CustomButton";
import styles from "./Onboarding.module.css";
import LoadingScreen from "../LoadingScreen/LoadingScreen";

const Onboarding = () => {
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000); // Simulating a 3-second load time
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  const handleNext = () => {
    if (currentIndex < contents.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      alert("Onboarding complete!");
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleComplete = () => {
    navigate("/login");
  };

  const contents = [
    {
      image: "/images/Frame5.png",
      data: (
        <div className={styles.content}>
          <h3>Scale your organization and effectively manage all activities</h3>
          <p>
            Project Nova will help your organization run like a well-oiled
            machine with all our available features.
          </p>
          <div className={styles.btn}>
            <CustomButton onClick={handleNext} size="small" customWidth="100%">
              Next
            </CustomButton>
          </div>
        </div>
      ),
    },
    {
      image: "/images/Frame2.png",
      data: (
        <div className={styles.content}>
          <h3>Scale your organization and effectively manage all activities</h3>
          <p>
            Project Nova will help your organization run like a well-oiled
            machine with all our available features.
          </p>
          <div className={styles.btns}>
            <CustomButton
              onClick={handlePrevious}
              size="small"
              variant="outlined"
              customWidth="49%"
            >
              Previous
            </CustomButton>
            <CustomButton onClick={handleNext} size="small" customWidth="49%">
              Next
            </CustomButton>
          </div>
        </div>
      ),
    },
    {
      image: "/images/Frame3.png",
      data: (
        <div className={styles.content}>
          <h3>Scale your organization and effectively manage all activities</h3>
          <p>
            Project Nova will help your organization run like a well-oiled
            machine with all our available features.
          </p>
          <div className={styles.btns}>
            <CustomButton
              onClick={handlePrevious}
              size="small"
              variant="outlined"
              customWidth="49%"
            >
              Previous
            </CustomButton>
            <CustomButton onClick={handleNext} size="small" customWidth="49%">
              Next
            </CustomButton>
          </div>
        </div>
      ),
    },
    {
      image: "/images/Frame4.png",
      data: (
        <div className={styles.content}>
          <h3>Scale your organization and effectively manage all activities</h3>
          <p>
            Project Nova will help your organization run like a well-oiled
            machine with all our available features.
          </p>
          <div className={styles.btn}>
            <CustomButton
              onClick={handleComplete}
              size="small"
              customWidth="100%"
            >
              Complete
            </CustomButton>
          </div>
        </div>
      ),
    },
  ];

  return (
    <AuthLayout
      imageComponent={<img src={contents[currentIndex].image} alt="Image" />}
      contentComponent={contents[currentIndex].data}
    />
  );
};

export default Onboarding;
