import React from "react";
import styles from "./ErrorPage.module.css";
import CustomButton from "../../components/inputs/button/CustomButton";
import { useNavigate } from "react-router-dom";

const Error_page = () => {
  const navigate = useNavigate();

  const gotoLogin = () => {
    navigate("/login");
  };

  const goBackHandler = () => {
    navigate(-1);
  };
  return (
    <div className={["container", styles.wrapper].join(" ")}>
      <div className={styles.content}>
        <img src="/images/error.png" alt="error image" />
        <h3>Oops! 404 Error</h3>
        <p>
          It looks like you found the doorway to the great nothing, the page you
          are looking for cannot be found.
        </p>
        <div className={styles.btns}>
          <CustomButton
            onClick={goBackHandler}
            size="small"
            variant="outlined"
            customWidth="49%"
          >
            Go Back
          </CustomButton>
          <CustomButton onClick={gotoLogin} size="small" customWidth="49%">
            Go to Login
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default Error_page;
