import React, { useState } from "react";
import DashboardLayout from "../../../../layouts/dashboard/DashboardLayout";
import styles from "./Projects.module.css";
import CustomTextInputWithIcon from "../../../../components/inputs/textInputWithIcon/CustomInputWithIcon";
import ButtonWithIcon from "../../../../components/btnWithIcon/ButtonWithIcon";
import { MdSearch } from "react-icons/md";
import { IoFilter } from "react-icons/io5";
import Projects from "../../../../components/dashboard/categories/projects/Projects";
import { Dropdown } from "react-bootstrap";

const ProjectsPage = () => {
  return (
    <DashboardLayout>
      <div className={styles.container}>
        <div className={styles.breadcrumbs}>
          <a href="#">Workspace / Categories / Projects</a>
        </div>

        <div className={styles.projectsControllers}>
          <div className={styles.filters}>
            <div className={styles.searchfield}>
              <CustomTextInputWithIcon
                value=""
                onChange={console.log("")}
                placeholder="Type to search..."
                icon={MdSearch}
                width="100%"
              />
            </div>
            <span className={styles.btnFilter}>
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className={styles.toggleBtn}
                >
                  <ButtonWithIcon
                    onClick={() => console.log("filter")}
                    icon={IoFilter}
                    label="Filter"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu className={styles.dropdownMenu}>
                  <Dropdown.Item
                    href="#/action-1"
                    className={styles.dropdownItem}
                  >
                    Project Manager
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#/action-2"
                    className={styles.dropdownItem}
                  >
                    Priority
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#/action-3"
                    className={styles.dropdownItem}
                  >
                    End Date
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#/action-3"
                    className={styles.dropdownItem}
                  >
                    T-Shirt Size
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </span>
          </div>
        </div>

        <Projects />
      </div>
    </DashboardLayout>
  );
};

export default ProjectsPage;
